import React, { Fragment } from 'react';
import { commonSourceTypes, dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import withCopyEdit from '../data-container';
import Verification from '../data-components/verification';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { DataTitleCopyable } from '../data-components/data-title';
import Tooltip from '../../components/tooltip';
import './land-use.css';
/**
 * Use view/edit section
 */
const LandUseView = (props) => {
    const switchToIsDomesticMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('is_domestic');
    };
    const switchToLandUseMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('landuse');
    };
    const landuseCodesData = { "Growing of cereals, other than rice, leguminous crops and oil seeds": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.11", description: "Cereals, other than rice, leguminous crops and oil seeds" },
            ISIC: { code: "0111", description: "Growing of cereals (except rice), leguminous crops and oil seeds" },
            NACE: { code: "01.11", description: "Growing of cereals, other than rice, leguminous crops and oil seeds" },
            UK_SIC: { code: "01.11", description: "Growing of cereals (except rice), leguminous crops and oil seeds" },
        },
        "Growing of rice": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.12", description: "Rice, not husked" },
            ISIC: { code: "0112", description: "Growing of rice" },
            NACE: { code: "01.12", description: "Growing of rice" },
            UK_SIC: { code: "01.12", description: "Growing of rice" },
        },
        "Growing of vegetables and melons, roots and tubers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.13", description: "Vegetables and melons, roots and tubers" },
            ISIC: { code: "0113", description: "Growing of vegetables and melons, roots and tubers" },
            NACE: { code: "01.13", description: "Growing of vegetables and melons, roots and tubers" },
            UK_SIC: { code: "01.13", description: "Growing of vegetables and melons, roots and tubers" },
        },
        "Growing of sugar cane": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.14", description: "Sugar cane" },
            ISIC: { code: "0114", description: "Growing of sugar cane" },
            NACE: { code: "01.14", description: "Growing of sugar cane" },
            UK_SIC: { code: "01.14", description: "Growing of sugar cane" },
        },
        "Growing of tobacco": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.15", description: "Unmanufactured tobacco" },
            ISIC: { code: "0115", description: "Growing of tobacco" },
            NACE: { code: "01.15", description: "Growing of tobacco" },
            UK_SIC: { code: "01.15", description: "Growing of tobacco" },
        },
        "Growing of fibre crops": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.16", description: "Fibre crops" },
            ISIC: { code: "0116", description: "Growing of fibre crops" },
            NACE: { code: "01.16", description: "Growing of fibre crops" },
            UK_SIC: { code: "01.16", description: "Growing of fibre crops" },
        },
        "Growing of other non-perennial crops": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.19", description: "Other non-perennial crops" },
            ISIC: { code: "0119", description: "Growing of other non-perennial crops" },
            NACE: { code: "01.19", description: "Growing of other non-perennial crops" },
            UK_SIC: { code: "01.19", description: "Growing of other non-perennial crops" },
        },
        "Growing of grapes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.21", description: "Fresh grapes" },
            ISIC: { code: "0121", description: "Growing of grapes" },
            NACE: { code: "01.21", description: "Growing of grapes" },
            UK_SIC: { code: "01.21", description: "Growing of grapes" },
        },
        "Growing of tropical and subtropical fruits": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.22", description: "Fresh tropical and subtropical fruits" },
            ISIC: { code: "0122", description: "Growing of tropical and subtropical fruits" },
            NACE: { code: "01.22", description: "Growing of tropical and subtropical fruits" },
            UK_SIC: { code: "01.22", description: "Growing of tropical and subtropical fruits" },
        },
        "Growing of citrus fruits": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.23", description: "Fresh citrus fruits" },
            ISIC: { code: "0123", description: "Growing of citrus fruits" },
            NACE: { code: "01.23", description: "Growing of citrus fruits" },
            UK_SIC: { code: "01.23", description: "Growing of citrus fruits" },
        },
        "Growing of pome fruits and stone fruits": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.24", description: "Fresh pome fruits and fresh stone fruits" },
            ISIC: { code: "0124", description: "Growing of pome fruits and stone fruits" },
            NACE: { code: "01.24", description: "Growing of pome fruits and stone fruits" },
            UK_SIC: { code: "01.24", description: "Growing of pome fruits and stone fruits" },
        },
        "Growing of other tree and bush fruits and nuts": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.25", description: "Other tree and bush fruits and nuts" },
            ISIC: { code: "0125", description: "Growing of other tree and bush fruits and nuts" },
            NACE: { code: "01.25", description: "Growing of other tree and bush fruits and nuts" },
            UK_SIC: { code: "01.25", description: "Growing of other tree and bush fruits and nuts" },
        },
        "Growing of oleaginous fruits": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.26", description: "Oleaginous fruits" },
            ISIC: { code: "0126", description: "Growing of oleaginous fruits" },
            NACE: { code: "01.26", description: "Growing of oleaginous fruits" },
            UK_SIC: { code: "01.26", description: "Growing of oleaginous fruits" },
        },
        "Growing of beverage crops": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.27", description: "Beverage crops" },
            ISIC: { code: "0127", description: "Growing of beverage crops" },
            NACE: { code: "01.27", description: "Growing of beverage crops" },
            UK_SIC: { code: "01.27", description: "Growing of beverage crops" },
        },
        "Growing of spices, aromatic, drug and pharmaceutical crops": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.28", description: "Spices, aromatic, drug and pharmaceutical crops" },
            ISIC: { code: "0128", description: "Growing of spices, aromatic, drug and pharmaceutical crops" },
            NACE: { code: "01.28", description: "Growing of spices, aromatic, drug and pharmaceutical crops" },
            UK_SIC: { code: "01.28", description: "Growing of spices, aromatic, drug and pharmaceutical crops" },
        },
        "Growing of other perennial crops": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.29", description: "Other perennial crops" },
            ISIC: { code: "0129", description: "Growing of other perennial crops" },
            NACE: { code: "01.29", description: "Growing of other perennial crops" },
            UK_SIC: { code: "01.29", description: "Growing of other perennial crops" },
        },
        "Plant propagation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.30", description: "Planting material: live plants, bulbs, tubers and roots, cuttings and slips; mushroom spawn" },
            ISIC: { code: "0130", description: "Plant propagation" },
            NACE: { code: "01.30", description: "Plant propagation" },
            UK_SIC: { code: "01.30", description: "Plant propagation" },
        },
        "Raising of dairy cattle": {
            SCAT: { code: "50", description: "Cattle Breeding Centres" },
            CPA: { code: "01.41", description: "Dairy cattle, live; raw milk from dairy cattle" },
            ISIC: { code: "0141", description: "Raising of cattle and buffaloes" },
            NACE: { code: "01.41", description: "Raising of dairy cattle" },
            UK_SIC: { code: "01.41", description: "Raising of dairy cattle" },
        },
        "Raising of other cattle and buffaloes": {
            SCAT: { code: "50", description: "Cattle Breeding Centres" },
            CPA: { code: "01.42", description: "Other cattle and buffaloes, live and their semen" },
            ISIC: { code: "0142", description: "Raising of horses and other equines" },
            NACE: { code: "01.42", description: "Raising of other cattle and buffaloes" },
            UK_SIC: { code: "01.42", description: "Raising of other cattle and buffaloes" },
        },
        "Raising of horses and other equines": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.43", description: "Horses and other equines, live" },
            ISIC: { code: "0143", description: "Raising of camels and camelids" },
            NACE: { code: "01.43", description: "Raising of horses and other equines" },
            UK_SIC: { code: "01.43", description: "Raising of horses and other equines" },
        },
        "Raising of camels and camelids": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.44", description: "Camels and camelids, live" },
            ISIC: { code: "0144", description: "Raising of sheep and goats" },
            NACE: { code: "01.44", description: "Raising of camels and camelids" },
            UK_SIC: { code: "01.44", description: "Raising of camels and camelids" },
        },
        "Raising of sheep and goats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.45", description: "Sheep and goats, live; raw milk and shorn wool from sheep and goats" },
            ISIC: { code: "0145", description: "Raising of swine and pigs" },
            NACE: { code: "01.45", description: "Raising of sheep and goats" },
            UK_SIC: { code: "01.45", description: "Raising of sheep and goats" },
        },
        "Raising of swine and pigs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.46", description: "Swine, live" },
            ISIC: { code: "0146", description: "Raising of poultry" },
            NACE: { code: "01.46", description: "Raising of swine and pigs" },
            UK_SIC: { code: "01.46", description: "Raising of swine/pigs" },
        },
        "Raising of poultry": {
            SCAT: { code: "124", description: "Hatcheries/Poultry Farms" },
            CPA: { code: "01.47", description: "Poultry, live and eggs" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "01.47", description: "Raising of poultry" },
            UK_SIC: { code: "01.47", description: "Raising of poultry" },
        },
        "Raising of other animals": {
            SCAT: { code: "112", description: "Game Farms" },
            CPA: { code: "01.48", description: "Other animals and animal products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "01.48", description: "Raising of other animals" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Mixed farming": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0150", description: "Mixed farming" },
            NACE: { code: "01.50", description: "Mixed farming" },
            UK_SIC: { code: "01.50", description: "Mixed farming" },
        },
        "Support activities for crop production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.61", description: "Support services to crop production" },
            ISIC: { code: "0161", description: "Support activities for crop production" },
            NACE: { code: "01.61", description: "Support activities for crop production" },
            UK_SIC: { code: "01.61", description: "Support activities for crop production" },
        },
        "Support activities for animal production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.62", description: "Support services to animal production; services of preparing farmyard manure and slurry for energy production" },
            ISIC: { code: "0162", description: "Support activities for animal production" },
            NACE: { code: "01.62", description: "Support activities for animal production" },
            UK_SIC: { code: "01.62", description: "Support activities for animal production" },
        },
        "Post-harvest crop activities and seed processing for propagation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.63", description: "Post-harvest crop services and seed processing services of propagation; services of preparing plant silages for energy production" },
            ISIC: { code: "0163", description: "Post-harvest crop activities" },
            NACE: { code: "01.63", description: "Post-harvest crop activities and seed processing for propagation" },
            UK_SIC: { code: "01.63", description: "Post-harvest crop activities" },
        },
        "Hunting, trapping and related service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "01.70", description: "Hunting and trapping and related services" },
            ISIC: { code: "0170", description: "Hunting, trapping and related service activities" },
            NACE: { code: "01.70", description: "Hunting, trapping and related service activities" },
            UK_SIC: { code: "01.70", description: "Hunting, trapping and related service activities" },
        },
        "Silviculture and other forestry activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "02.10", description: "Forest trees and nursery services" },
            ISIC: { code: "0210", description: "Silviculture and other forestry activities" },
            NACE: { code: "02.10", description: "Silviculture and other forestry activities" },
            UK_SIC: { code: "02.10", description: "Silviculture and other forestry activities" },
        },
        "Logging": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "02.20", description: "Wood in the rough" },
            ISIC: { code: "0220", description: "Logging" },
            NACE: { code: "02.20", description: "Logging" },
            UK_SIC: { code: "02.20", description: "Logging" },
        },
        "Gathering of wild growing non-wood products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "02.30", description: "Wild growing non-wood products" },
            ISIC: { code: "0230", description: "Gathering of non-wood forest products" },
            NACE: { code: "02.30", description: "Gathering of wild growing non-wood products" },
            UK_SIC: { code: "02.30", description: "Gathering of wild growing non-wood products" },
        },
        "Support services to forestry": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "02.40", description: "Support services to forestry" },
            ISIC: { code: "0240", description: "Support services to forestry" },
            NACE: { code: "02.40", description: "Support services to forestry" },
            UK_SIC: { code: "02.40", description: "Support services to forestry" },
        },
        "Marine fishing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0311", description: "Marine fishing" },
            NACE: { code: "03.11", description: "Marine fishing" },
            UK_SIC: { code: "03.11", description: "Marine fishing" },
        },
        "Freshwater fishing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0312", description: "Freshwater fishing" },
            NACE: { code: "03.12", description: "Freshwater fishing" },
            UK_SIC: { code: "03.12", description: "Freshwater fishing" },
        },
        "Marine aquaculture": {
            SCAT: { code: "102", description: "Fish Farms" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0321", description: "Marine aquaculture" },
            NACE: { code: "03.21", description: "Marine aquaculture" },
            UK_SIC: { code: "03.21", description: "Marine aquaculture" },
        },
        "Freshwater aquaculture": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0322", description: "Freshwater aquaculture" },
            NACE: { code: "03.22", description: "Freshwater aquaculture" },
            UK_SIC: { code: "03.22", description: "Freshwater aquaculture" },
        },
        "Support activities for fishing and aquaculture": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "0330", description: "Support activities for fishing and aquaculture" },
            NACE: { code: "03.30", description: "Support activities for fishing and aquaculture" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Mining of hard coal": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "05.10", description: "Hard coal and other coal" },
            ISIC: { code: "0510", description: "Mining of hard coal" },
            NACE: { code: "05.10", description: "Mining of hard coal" },
            UK_SIC: { code: "05.10", description: "Mining of hard coal" },
        },
        "Mining of lignite": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "05.20", description: "Lignite" },
            ISIC: { code: "0520", description: "Mining of lignite" },
            NACE: { code: "05.20", description: "Mining of lignite" },
            UK_SIC: { code: "05.20", description: "Mining of lignite" },
        },
        "Extraction of crude petroleum": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "06.10", description: "Crude petroleum" },
            ISIC: { code: "0610", description: "Extraction of crude petroleum" },
            NACE: { code: "06.10", description: "Extraction of crude petroleum" },
            UK_SIC: { code: "06.10", description: "Extraction of crude petroleum" },
        },
        "Extraction of natural gas": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "06.20", description: "Natural gas, liquefied or in gaseous state" },
            ISIC: { code: "0620", description: "Extraction of natural gas" },
            NACE: { code: "06.20", description: "Extraction of natural gas" },
            UK_SIC: { code: "06.20", description: "Extraction of natural gas" },
        },
        "Mining of iron ores": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "07.10", description: "Iron ores" },
            ISIC: { code: "0710", description: "Mining of iron ores" },
            NACE: { code: "07.10", description: "Mining of iron ores" },
            UK_SIC: { code: "07.10", description: "Mining of iron ores" },
        },
        "Mining of uranium and thorium ores": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "07.21", description: "Uranium and thorium ores" },
            ISIC: { code: "0721", description: "Mining of uranium and thorium ores" },
            NACE: { code: "07.21", description: "Mining of uranium and thorium ores" },
            UK_SIC: { code: "07.21", description: "Mining of uranium and thorium ores" },
        },
        "Mining of other non-ferrous metal ores": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "07.29", description: "Other non-ferrous metal ores and concentrates" },
            ISIC: { code: "0729", description: "Mining of other non-ferrous metal ores" },
            NACE: { code: "07.29", description: "Mining of other non-ferrous metal ores" },
            UK_SIC: { code: "07.29", description: "Mining of other non-ferrous metal ores" },
        },
        "Quarrying of ornamental stone, limestone, gypsum, slate and other stone": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "08.11", description: "Ornamental stone, limestone, gypsum, slate and other stone" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "08.11", description: "Quarrying of ornamental stone, limestone, gypsum, slate and other stone" },
            UK_SIC: { code: "08.11", description: "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate" },
        },
        "Operation of gravel and sand pits and mining of clay and kaolin": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "08.12", description: "Gravel, sand, clay and kaolin" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "08.12", description: "Operation of gravel and sand pits and mining of clay and kaolin" },
            UK_SIC: { code: "08.12", description: "Operation of gravel and sand pits; mining of clays and kaolin" },
        },
        "Mining of chemical and fertiliser minerals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "08.91", description: "Chemical and fertiliser minerals" },
            ISIC: { code: "0891", description: "Mining of chemical and fertilizer minerals" },
            NACE: { code: "08.91", description: "Mining of chemical and fertiliser minerals" },
            UK_SIC: { code: "08.91", description: "Mining of chemical and fertiliser minerals" },
        },
        "Extraction of peat": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "08.92", description: "Peat" },
            ISIC: { code: "0892", description: "Extraction of peat" },
            NACE: { code: "08.92", description: "Extraction of peat" },
            UK_SIC: { code: "08.92", description: "Extraction of peat" },
        },
        "Extraction of salt": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "08.93", description: "Salt and pure sodium chloride; sea water" },
            ISIC: { code: "0893", description: "Extraction of salt" },
            NACE: { code: "08.93", description: "Extraction of salt" },
            UK_SIC: { code: "08.93", description: "Extraction of salt" },
        },
        "Other mining and quarrying not elsewhere classified": {
            SCAT: { code: "127", description: "Heredits Used For Primary Treatment/Processing Of Minerals" },
            CPA: { code: "08.99", description: "Other mining and quarrying products n.e.c." },
            ISIC: { code: "0899", description: "Other mining and quarrying not elsewhere classified" },
            NACE: { code: "08.99", description: "Other mining and quarrying not elsewhere classified" },
            UK_SIC: { code: "08.99", description: "Other mining and quarrying n.e.c." },
        },
        "Support activities for petroleum and natural gas extraction": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "09.10", description: "Support services to petroleum and natural gas extraction" },
            ISIC: { code: "0910", description: "Support activities for petroleum and natural gas extraction" },
            NACE: { code: "09.10", description: "Support activities for petroleum and natural gas extraction" },
            UK_SIC: { code: "09.10", description: "Support activities for petroleum and natural gas extraction" },
        },
        "Support activities for other mining and quarrying": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "09.90", description: "Support services to other mining and quarrying" },
            ISIC: { code: "0990", description: "Support activities for other mining and quarrying" },
            NACE: { code: "09.90", description: "Support activities for other mining and quarrying" },
            UK_SIC: { code: "09.90", description: "Support activities for other mining and quarrying" },
        },
        "Processing and preserving of meat, except of poultry meat": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.11", description: "Processed and preserved meat, except poultry meat" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.11", description: "Processing and preserving of meat, except of poultry meat" },
            UK_SIC: { code: "10.11", description: "Processing and preserving of meat" },
        },
        "Processing and preserving of poultry meat": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.12", description: "Processed and preserved poultry meat" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.12", description: "Processing and preserving of poultry meat" },
            UK_SIC: { code: "10.12", description: "Processing and preserving of poultry meat" },
        },
        "Production of meat and poultry meat products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.13", description: "Preserves and preparations of meat, meat offal or blood" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.13", description: "Production of meat and poultry meat products" },
            UK_SIC: { code: "10.13", description: "Production of meat and poultry meat products" },
        },
        "Processing and preserving of fish, crustaceans and molluscs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.20", description: "Processed and preserved fish, crustaceans and molluscs" },
            ISIC: { code: "1020", description: "Processing and preserving of fish, crustaceans and molluscs" },
            NACE: { code: "10.20", description: "Processing and preserving of fish, crustaceans and molluscs" },
            UK_SIC: { code: "10.20", description: "Processing and preserving of fish, crustaceans and molluscs" },
        },
        "Processing and preserving of potatoes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.31", description: "Processed and preserved potatoes" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.31", description: "Processing and preserving of potatoes" },
            UK_SIC: { code: "10.31", description: "Processing and preserving of potatoes" },
        },
        "Manufacture of fruit and vegetable juice": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.32", description: "Fruit and vegetable juices" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.32", description: "Manufacture of fruit and vegetable juice" },
            UK_SIC: { code: "10.32", description: "Manufacture of fruit and vegetable juice" },
        },
        "Other processing and preserving of fruit and vegetables": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.39", description: "Other processed and preserved fruit and vegetables" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.39", description: "Other processing and preserving of fruit and vegetables" },
            UK_SIC: { code: "10.39", description: "Other processing and preserving of fruit and vegetables" },
        },
        "Manufacture of oils and fats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.41", description: "Oils and fats" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.41", description: "Manufacture of oils and fats" },
            UK_SIC: { code: "10.41", description: "Manufacture of oils and fats" },
        },
        "Manufacture of margarine and similar edible fats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.42", description: "Margarine and similar edible fats" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.42", description: "Manufacture of margarine and similar edible fats" },
            UK_SIC: { code: "10.42", description: "Manufacture of margarine and similar edible fats" },
        },
        "Manufacture of dairy products": {
            SCAT: { code: "79", description: "Creameries" },
            CPA: { code: "10.51", description: "Dairy and cheese products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.51", description: "Manufacture of dairy products" },
            UK_SIC: { code: "10.51", description: "Operation of dairies and cheese making" },
        },
        "Manufacture of ice cream and other edible ice": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.52", description: "Ice cream and other edible ice" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.52", description: "Manufacture of ice cream and other edible ice" },
            UK_SIC: { code: "10.52", description: "Manufacture of ice cream" },
        },
        "Manufacture of grain mill products": {
            SCAT: { code: "103", description: "Flour Mills (National Scheme)" },
            CPA: { code: "10.61", description: "Grain mill products" },
            ISIC: { code: "1061", description: "Manufacture of grain mill products" },
            NACE: { code: "10.61", description: "Manufacture of grain mill products" },
            UK_SIC: { code: "10.61", description: "Manufacture of grain mill products" },
        },
        "Manufacture of starches and starch products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.62", description: "Starches and starch products" },
            ISIC: { code: "1062", description: "Manufacture of starches and starch products" },
            NACE: { code: "10.62", description: "Manufacture of starches and starch products" },
            UK_SIC: { code: "10.62", description: "Manufacture of starches and starch products" },
        },
        "Manufacture of bread; manufacture of fresh pastry goods and cakes": {
            SCAT: { code: "96", description: "Factories, Workshops and Warehouses (Incl Bakeries & Dairies)" },
            CPA: { code: "10.71", description: "Bread, fresh pastry goods and cakes" },
            ISIC: { code: "1071", description: "Manufacture of bakery products" },
            NACE: { code: "10.71", description: "Manufacture of bread; manufacture of fresh pastry goods and cakes" },
            UK_SIC: { code: "10.71", description: "Manufacture of bread; manufacture of fresh pastry goods and cakes" },
        },
        "Manufacture of rusks, biscuits, preserved pastries and cakes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.72", description: "Rusks and biscuits; preserved pastry goods and cakes" },
            ISIC: { code: "1072", description: "Manufacture of sugar" },
            NACE: { code: "10.72", description: "Manufacture of rusks, biscuits, preserved pastries and cakes" },
            UK_SIC: { code: "10.72", description: "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes" },
        },
        "Manufacture of farinaceous products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.73", description: "Macaroni, noodles, couscous and similar farinaceous products" },
            ISIC: { code: "1073", description: "Manufacture of cocoa, chocolate and sugar confectionery" },
            NACE: { code: "10.73", description: "Manufacture of farinaceous products" },
            UK_SIC: { code: "10.73", description: "Manufacture of macaroni, noodles, couscous and similar farinaceous products" },
        },
        "Manufacture of sugar": {
            SCAT: { code: "23", description: "Beet Sugar Factories" },
            CPA: { code: "10.81", description: "Sugar" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.81", description: "Manufacture of sugar" },
            UK_SIC: { code: "10.81", description: "Manufacture of sugar" },
        },
        "Manufacture of cocoa, chocolate and sugar confectionery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.82", description: "Cocoa, chocolate and sugar confectionery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.82", description: "Manufacture of cocoa, chocolate and sugar confectionery" },
            UK_SIC: { code: "10.82", description: "Manufacture of cocoa, chocolate and sugar confectionery" },
        },
        "Processing of tea and coffee": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.83", description: "Processed tea and coffee" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.83", description: "Processing of tea and coffee" },
            UK_SIC: { code: "10.83", description: "Processing of tea and coffee" },
        },
        "Manufacture of condiments and seasonings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.84", description: "Condiments and seasonings" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.84", description: "Manufacture of condiments and seasonings" },
            UK_SIC: { code: "10.84", description: "Manufacture of condiments and seasonings" },
        },
        "Manufacture of prepared meals and dishes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.85", description: "Prepared meals and dishes" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.85", description: "Manufacture of prepared meals and dishes" },
            UK_SIC: { code: "10.85", description: "Manufacture of prepared meals and dishes" },
        },
        "Manufacture of homogenised food preparations and dietetic food": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.86", description: "Homogenised food preparations and dietetic food" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.86", description: "Manufacture of homogenised food preparations and dietetic food" },
            UK_SIC: { code: "10.86", description: "Manufacture of homogenised food preparations and dietetic food" },
        },
        "Manufacture of other food products not elsewhere classified": {
            SCAT: { code: "105", description: "Food Processing Centres" },
            CPA: { code: "10.89", description: "Other food products n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.89", description: "Manufacture of other food products not elsewhere classified" },
            UK_SIC: { code: "10.89", description: "Manufacture of other food products n.e.c." },
        },
        "Manufacture of prepared feeds for farm animals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.91", description: "Prepared feeds for farm animals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.91", description: "Manufacture of prepared feeds for farm animals" },
            UK_SIC: { code: "10.91", description: "Manufacture of prepared feeds for farm animals" },
        },
        "Manufacture of prepared pet foods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "10.92", description: "Prepared pet foods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "10.92", description: "Manufacture of prepared pet foods" },
            UK_SIC: { code: "10.92", description: "Manufacture of prepared pet foods" },
        },
        "Distilling, rectifying and blending of spirits": {
            SCAT: { code: "87", description: "Distilleries" },
            CPA: { code: "11.01", description: "Distilled, rectified and blended spirits" },
            ISIC: { code: "1101", description: "Distilling, rectifying and blending of spirits" },
            NACE: { code: "11.01", description: "Distilling, rectifying and blending of spirits" },
            UK_SIC: { code: "11.01", description: "Distilling, rectifying and blending of spirits" },
        },
        "Manufacture of wine from grape": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "11.02", description: "Wine from grape" },
            ISIC: { code: "1102", description: "Manufacture of wines" },
            NACE: { code: "11.02", description: "Manufacture of wine from grape" },
            UK_SIC: { code: "11.02", description: "Manufacture of wine from grape" },
        },
        "Manufacture of cider and other fruit fermented beverages": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "11.03", description: "Other fermented beverages (e.g., cider, perry, mead); mixed beverages containing alcohol" },
            ISIC: { code: "1103", description: "Manufacture of beer" },
            NACE: { code: "11.03", description: "Manufacture of cider and other fruit fermented beverages" },
            UK_SIC: { code: "11.03", description: "Manufacture of cider and other fruit wines" },
        },
        "Manufacture of other non-distilled fermented beverages": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "11.04", description: "Other non-distilled fermented beverages" },
            ISIC: { code: "1104", description: "Manufacture of malt" },
            NACE: { code: "11.04", description: "Manufacture of other non-distilled fermented beverages" },
            UK_SIC: { code: "11.04", description: "Manufacture of other non-distilled fermented beverages" },
        },
        "Manufacture of beer": {
            SCAT: { code: "31", description: "Breweries (National Scheme)" },
            CPA: { code: "11.05", description: "Beer and brewing dregs" },
            ISIC: { code: "1105", description: "Manufacture of soft drinks; production of mineral waters and other bottled waters" },
            NACE: { code: "11.05", description: "Manufacture of beer" },
            UK_SIC: { code: "11.05", description: "Manufacture of beer" },
        },
        "Manufacture of malt": {
            SCAT: { code: "162\n163", description: "Maltings - Non Trad\nMaltings - Trad" },
            CPA: { code: "11.06", description: "Malt" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "11.06", description: "Manufacture of malt" },
            UK_SIC: { code: "11.06", description: "Manufacture of malt" },
        },
        "Manufacture of soft drinks and bottled waters": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "11.07", description: "Soft drinks and bottled waters" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "11.07", description: "Manufacture of soft drinks and bottled waters" },
            UK_SIC: { code: "11.07", description: "Manufacture of soft drinks; production of mineral waters and other bottled waters" },
        },
        "Manufacture of tobacco products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "12.00", description: "Tobacco products" },
            ISIC: { code: "1200", description: "Manufacture of tobacco products" },
            NACE: { code: "12.00", description: "Manufacture of tobacco products" },
            UK_SIC: { code: "12.00", description: "Manufacture of tobacco products" },
        },
        "Preparation and spinning of textile fibres": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.10", description: "Textile yarn and thread" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "13.10", description: "Preparation and spinning of textile fibres" },
            UK_SIC: { code: "13.10", description: "Preparation and spinning of textile fibres" },
        },
        "Weaving of textiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.20", description: "Woven textiles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "13.20", description: "Weaving of textiles" },
            UK_SIC: { code: "13.20", description: "Weaving of textiles" },
        },
        "Finishing of textiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.30", description: "Textile finishing services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "13.30", description: "Finishing of textiles" },
            UK_SIC: { code: "13.30", description: "Finishing of textiles" },
        },
        "Manufacture of knitted and crocheted fabrics": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.91", description: "Knitted and crocheted fabrics" },
            ISIC: { code: "1391", description: "Manufacture of knitted and crocheted fabrics" },
            NACE: { code: "13.91", description: "Manufacture of knitted and crocheted fabrics" },
            UK_SIC: { code: "13.91", description: "Manufacture of knitted and crocheted fabrics" },
        },
        "Manufacture of household textiles and made-up furnishing articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.92", description: "Household textiles and made-up furnishing articles" },
            ISIC: { code: "1392", description: "Manufacture of made-up textile articles, except apparel" },
            NACE: { code: "13.92", description: "Manufacture of household textiles and made-up furnishing articles" },
            UK_SIC: { code: "13.92", description: "Manufacture of made-up textile articles, except apparel" },
        },
        "Manufacture of carpets and rugs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.93", description: "Carpets and rugs" },
            ISIC: { code: "1393", description: "Manufacture of carpets and rugs" },
            NACE: { code: "13.93", description: "Manufacture of carpets and rugs" },
            UK_SIC: { code: "13.93", description: "Manufacture of carpets and rugs" },
        },
        "Manufacture of cordage, rope, twine and netting": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.94", description: "Cordage, rope, twine and netting" },
            ISIC: { code: "1394", description: "Manufacture of cordage, rope, twine and netting" },
            NACE: { code: "13.94", description: "Manufacture of cordage, rope, twine and netting" },
            UK_SIC: { code: "13.94", description: "Manufacture of cordage, rope, twine and netting" },
        },
        "Manufacture of non-wovens and non-woven articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.95", description: "Non-wovens and non-woven articles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "13.95", description: "Manufacture of non-wovens and non-woven articles" },
            UK_SIC: { code: "13.95", description: "Manufacture of non-wovens and articles made from non-wovens, except apparel" },
        },
        "Manufacture of other technical and industrial textiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.96", description: "Other technical and industrial textiles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "13.96", description: "Manufacture of other technical and industrial textiles" },
            UK_SIC: { code: "13.96", description: "Manufacture of other technical and industrial textiles" },
        },
        "Manufacture of other textiles not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "13.99", description: "Other textiles n.e.c." },
            ISIC: { code: "1399", description: "Manufacture of other textiles not elsewhere classified" },
            NACE: { code: "13.99", description: "Manufacture of other textiles not elsewhere classified" },
            UK_SIC: { code: "13.99", description: "Manufacture of other textiles n.e.c." },
        },
        "Manufacture of knitted and crocheted apparel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.10", description: "Knitted and crocheted apparel" },
            ISIC: { code: "1410", description: "Manufacture of wearing apparel, except fur apparel" },
            NACE: { code: "14.10", description: "Manufacture of knitted and crocheted apparel" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of outerwear": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.21", description: "Outerwear" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "14.21", description: "Manufacture of outerwear" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of underwear": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.22", description: "Underwear, other than knitted or crocheted" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "14.22", description: "Manufacture of underwear" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of workwear": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.23", description: "Workwear" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "14.23", description: "Manufacture of workwear" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of leather clothes and fur apparel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.24", description: "Leather clothes and fur apparel" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "14.24", description: "Manufacture of leather clothes and fur apparel" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of other wearing apparel and accessories not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "14.29", description: "Other wearing apparel and accessories n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "14.29", description: "Manufacture of other wearing apparel and accessories not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Tanning, dressing, dyeing of leather and fur": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "15.11", description: "Tanned, dressed, dyed leather and fur" },
            ISIC: { code: "1511", description: "Tanning and dressing of leather; dressing and dyeing of fur" },
            NACE: { code: "15.11", description: "Tanning, dressing, dyeing of leather and fur" },
            UK_SIC: { code: "15.11", description: "Tanning and dressing of leather; dressing and dyeing of fur" },
        },
        "Manufacture of luggage, handbags, saddlery and harness of any material": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "15.12", description: "Saddlery and harness; luggage, handbags and the like; other articles of leather" },
            ISIC: { code: "1512", description: "Manufacture of luggage, handbags and the like, saddlery and harness of any material" },
            NACE: { code: "15.12", description: "Manufacture of luggage, handbags, saddlery and harness of any material" },
            UK_SIC: { code: "15.12", description: "Manufacture of luggage, handbags and the like, saddlery and harness" },
        },
        "Manufacture of footwear": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "15.20", description: "Footwear" },
            ISIC: { code: "1520", description: "Manufacture of footwear" },
            NACE: { code: "15.20", description: "Manufacture of footwear" },
            UK_SIC: { code: "15.20", description: "Manufacture of footwear" },
        },
        "Sawmilling and planing of wood": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.11", description: "Wood, sawn and planed" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.11", description: "Sawmilling and planing of wood" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Processing and finishing of wood": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.12", description: "Processing and finishing services of wood" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.12", description: "Processing and finishing of wood" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of veneer sheets and wood-based panels": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.21", description: "Veneer sheets and wood-based panels" },
            ISIC: { code: "1621", description: "Manufacture of veneer sheets and wood-based panels" },
            NACE: { code: "16.21", description: "Manufacture of veneer sheets and wood-based panels" },
            UK_SIC: { code: "16.21", description: "Manufacture of veneer sheets and wood-based panels" },
        },
        "Manufacture of assembled parquet floors": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.22", description: "Assembled parquet floors" },
            ISIC: { code: "1622", description: "Manufacture of builders' carpentry and joinery" },
            NACE: { code: "16.22", description: "Manufacture of assembled parquet floors" },
            UK_SIC: { code: "16.22", description: "Manufacture of assembled parquet floors" },
        },
        "Manufacture of other builders' carpentry and joinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.23", description: "Other builders' carpentry and joinery" },
            ISIC: { code: "1623", description: "Manufacture of wooden containers" },
            NACE: { code: "16.23", description: "Manufacture of other builders' carpentry and joinery" },
            UK_SIC: { code: "16.23", description: "Manufacture of other builders' carpentry and joinery" },
        },
        "Manufacture of wooden containers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.24", description: "Wooden containers" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.24", description: "Manufacture of wooden containers" },
            UK_SIC: { code: "16.24", description: "Manufacture of wooden containers" },
        },
        "Manufacture of doors and windows of wood": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.25", description: "Windows, French windows and their frames, doors and their frames and thresholds, of wood" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.25", description: "Manufacture of doors and windows of wood" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of solid fuels from vegetable biomass": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.26", description: "Solid fuels from vegetable biomass" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.26", description: "Manufacture of solid fuels from vegetable biomass" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Finishing of wooden products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.27", description: "Finishing services of wooden products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.27", description: "Finishing of wooden products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of other products of wood and articles of cork, straw and plaiting materials": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "16.28", description: "Other products of wood and articles of cork, straw and plaiting materials" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "16.28", description: "Manufacture of other products of wood and articles of cork, straw and plaiting materials" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of pulp": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.11", description: "Pulps of wood or other fibrous cellulosic material" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.11", description: "Manufacture of pulp" },
            UK_SIC: { code: "17.11", description: "Manufacture of pulp" },
        },
        "Manufacture of paper and paperboard": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.12", description: "Paper and paperboard" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.12", description: "Manufacture of paper and paperboard" },
            UK_SIC: { code: "17.12", description: "Manufacture of paper and paperboard" },
        },
        "Manufacture of corrugated paper, paperboard and containers of paper and paperboard": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.21", description: "Corrugated paper, paperboard and containers of paper and paperboard" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.21", description: "Manufacture of corrugated paper, paperboard and containers of paper and paperboard" },
            UK_SIC: { code: "17.21", description: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard" },
        },
        "Manufacture of household and sanitary goods and of toilet requisites": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.22", description: "Household and sanitary goods and toilet requisites" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.22", description: "Manufacture of household and sanitary goods and of toilet requisites" },
            UK_SIC: { code: "17.22", description: "Manufacture of household and sanitary goods and of toilet requisites" },
        },
        "Manufacture of paper stationery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.23", description: "Paper stationery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.23", description: "Manufacture of paper stationery" },
            UK_SIC: { code: "17.23", description: "Manufacture of paper stationery" },
        },
        "Manufacture of wallpaper": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.24", description: "Wallpaper" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.24", description: "Manufacture of wallpaper" },
            UK_SIC: { code: "17.24", description: "Manufacture of wallpaper" },
        },
        "Manufacture of other articles of paper and paperboard": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "17.25", description: "Other articles of paper and paperboard" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "17.25", description: "Manufacture of other articles of paper and paperboard" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Printing of newspapers": {
            SCAT: { code: "198", description: "Newspaper Printing Works (National Scheme)" },
            CPA: { code: "18.11", description: "Newspaper printing services" },
            ISIC: { code: "1811", description: "Printing" },
            NACE: { code: "18.11", description: "Printing of newspapers" },
            UK_SIC: { code: "18.11", description: "Printing of newspapers" },
        },
        "Other printing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "18.12", description: "Other printing services" },
            ISIC: { code: "1812", description: "Service activities related to printing" },
            NACE: { code: "18.12", description: "Other printing" },
            UK_SIC: { code: "18.12", description: "Other printing" },
        },
        "Pre-press and pre-media services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "18.13", description: "Pre-press and pre-media services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "18.13", description: "Pre-press and pre-media services" },
            UK_SIC: { code: "18.13", description: "Pre-press and pre-media services" },
        },
        "Binding and related services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "18.14", description: "Binding and related services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "18.14", description: "Binding and related services" },
            UK_SIC: { code: "18.14", description: "Binding and related services" },
        },
        "Reproduction of recorded media": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "18.20", description: "Reproduction services of recorded media" },
            ISIC: { code: "1820", description: "Reproduction of recorded media" },
            NACE: { code: "18.20", description: "Reproduction of recorded media" },
            UK_SIC: { code: "18.20", description: "Reproduction of recorded media" },
        },
        "Manufacture of coke oven products": {
            SCAT: { code: "63", description: "Coking and Carbonising Plants" },
            CPA: { code: "19.10", description: "Coke oven products" },
            ISIC: { code: "1910", description: "Manufacture of coke oven products" },
            NACE: { code: "19.10", description: "Manufacture of coke oven products" },
            UK_SIC: { code: "19.10", description: "Manufacture of coke oven products" },
        },
        "Manufacture of refined petroleum products and fossil fuel products": {
            SCAT: { code: "63", description: "Coking and Carbonising Plants" },
            CPA: { code: "19.20", description: "Refined petroleum products and fossil fuel products" },
            ISIC: { code: "1920", description: "Manufacture of refined petroleum products; manufacture of fossil fuel products" },
            NACE: { code: "19.20", description: "Manufacture of refined petroleum products and fossil fuel products" },
            UK_SIC: { code: "19.20", description: "Manufacture of refined petroleum products" },
        },
        "Manufacture of industrial gases": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.11", description: "Industrial gases" },
            ISIC: { code: "2011", description: "Manufacture of basic chemicals" },
            NACE: { code: "20.11", description: "Manufacture of industrial gases" },
            UK_SIC: { code: "20.11", description: "Manufacture of industrial gases" },
        },
        "Manufacture of dyes and pigments": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.12", description: "Dyes and pigments" },
            ISIC: { code: "2012", description: "Manufacture of fertilizers and nitrogen compounds" },
            NACE: { code: "20.12", description: "Manufacture of dyes and pigments" },
            UK_SIC: { code: "20.12", description: "Manufacture of dyes and pigments" },
        },
        "Manufacture of other inorganic basic chemicals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.13", description: "Other inorganic basic chemicals" },
            ISIC: { code: "2013", description: "Manufacture of plastics and synthetic rubber in primary forms" },
            NACE: { code: "20.13", description: "Manufacture of other inorganic basic chemicals" },
            UK_SIC: { code: "20.13", description: "Manufacture of other inorganic basic chemicals" },
        },
        "Manufacture of other organic basic chemicals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.14", description: "Other organic basic chemicals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.14", description: "Manufacture of other organic basic chemicals" },
            UK_SIC: { code: "20.14", description: "Manufacture of other organic basic chemicals" },
        },
        "Manufacture of fertilisers and nitrogen compounds": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.15", description: "Fertilisers and nitrogen compounds" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.15", description: "Manufacture of fertilisers and nitrogen compounds" },
            UK_SIC: { code: "20.15", description: "Manufacture of fertilisers and nitrogen compounds" },
        },
        "Manufacture of plastics in primary forms": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.16", description: "Plastics in primary forms" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.16", description: "Manufacture of plastics in primary forms" },
            UK_SIC: { code: "20.16", description: "Manufacture of plastics in primary forms" },
        },
        "Manufacture of synthetic rubber in primary forms": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.17", description: "Synthetic rubber in primary forms" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.17", description: "Manufacture of synthetic rubber in primary forms" },
            UK_SIC: { code: "20.17", description: "Manufacture of synthetic rubber in primary forms" },
        },
        "Manufacture of pesticides, disinfectants and other agrochemical products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.20", description: "Pesticides, disinfectants and other agrochemical products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.20", description: "Manufacture of pesticides, disinfectants and other agrochemical products" },
            UK_SIC: { code: "20.20", description: "Manufacture of pesticides and other agrochemical products" },
        },
        "Manufacture of paints, varnishes and similar coatings, printing ink and mastics": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.30", description: "Paints, varnishes and similar coatings, printing ink and mastics" },
            ISIC: { code: "2030", description: "Manufacture of man-made fibres" },
            NACE: { code: "20.30", description: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics" },
            UK_SIC: { code: "20.30", description: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics" },
        },
        "Manufacture of soap and detergents, cleaning and polishing preparations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.41", description: "Soap and detergents, cleaning and polishing preparations" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.41", description: "Manufacture of soap and detergents, cleaning and polishing preparations" },
            UK_SIC: { code: "20.41", description: "Manufacture of soap and detergents, cleaning and polishing preparations" },
        },
        "Manufacture of perfumes and toilet preparations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.42", description: "Perfumes and toilet preparations" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.42", description: "Manufacture of perfumes and toilet preparations" },
            UK_SIC: { code: "20.42", description: "Manufacture of perfumes and toilet preparations" },
        },
        "Manufacture of liquid biofuels": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.51", description: "Liquid biofuels" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.51", description: "Manufacture of liquid biofuels" },
            UK_SIC: { code: "20.51", description: "Manufacture of explosives" },
        },
        "Manufacture of other chemical products not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "20.59", description: "Other chemical products n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.59", description: "Manufacture of other chemical products not elsewhere classified" },
            UK_SIC: { code: "20.59", description: "Manufacture of other chemical products n.e.c." },
        },
        "Manufacture of man-made fibres": {
            SCAT: { code: "16\n55", description: "Artificial Fibre Works\nChemical Works" },
            CPA: { code: "20.60", description: "Man-made fibres" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "20.60", description: "Manufacture of man-made fibres" },
            UK_SIC: { code: "20.60", description: "Manufacture of man-made fibres" },
        },
        "Manufacture of basic pharmaceutical products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "21.10", description: "Basic pharmaceutical products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "21.10", description: "Manufacture of basic pharmaceutical products" },
            UK_SIC: { code: "21.10", description: "Manufacture of basic pharmaceutical products" },
        },
        "Manufacture of pharmaceutical preparations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "21.20", description: "Pharmaceutical preparations" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "21.20", description: "Manufacture of pharmaceutical preparations" },
            UK_SIC: { code: "21.20", description: "Manufacture of pharmaceutical preparations" },
        },
        "Manufacture, retreading and rebuilding of rubber tyres and manufacture of tubes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.11", description: "Rubber tyres and tubes; retreading and rebuilding of rubber tyres" },
            ISIC: { code: "2211", description: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres" },
            NACE: { code: "22.11", description: "Manufacture, retreading and rebuilding of rubber tyres and manufacture of tubes" },
            UK_SIC: { code: "22.11", description: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres" },
        },
        "Manufacture of other rubber products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.12", description: "Other rubber products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.12", description: "Manufacture of other rubber products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of plastic plates, sheets, tubes and profiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.21", description: "Plastic plates, sheets, tubes and profiles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.21", description: "Manufacture of plastic plates, sheets, tubes and profiles" },
            UK_SIC: { code: "22.21", description: "Manufacture of plastic plates, sheets, tubes and profiles" },
        },
        "Manufacture of plastic packing goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.22", description: "Plastic packing goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.22", description: "Manufacture of plastic packing goods" },
            UK_SIC: { code: "22.22", description: "Manufacture of plastic packing goods" },
        },
        "Manufacture of doors and windows of plastic": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.23", description: "Doors, windows and their frames and thresholds for doors; shutters, blinds and similar articles and parts thereof, of plastics" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.23", description: "Manufacture of doors and windows of plastic" },
            UK_SIC: { code: "22.23", description: "Manufacture of builders’ ware of plastic" },
        },
        "Manufacture of builders ware of plastic": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.24", description: "Builders’ ware of plastics" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.24", description: "Manufacture of builders ware of plastic" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Processing and finishing of plastic products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "22.25", description: "Processing and finishing services of plastic products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.25", description: "Processing and finishing of plastic products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of other plastic products": {
            SCAT: { code: "728", description: "Bagging Plant & Premises" },
            CPA: { code: "22.26", description: "Other plastic products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "22.26", description: "Manufacture of other plastic products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of flat glass": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.11", description: "Flat glass" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.11", description: "Manufacture of flat glass" },
            UK_SIC: { code: "23.11", description: "Manufacture of flat glass" },
        },
        "Shaping and processing of flat glass": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.12", description: "Shaped and processed flat glass" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.12", description: "Shaping and processing of flat glass" },
            UK_SIC: { code: "23.12", description: "Shaping and processing of flat glass" },
        },
        "Manufacture of hollow glass": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.13", description: "Hollow glass" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.13", description: "Manufacture of hollow glass" },
            UK_SIC: { code: "23.13", description: "Manufacture of hollow glass" },
        },
        "Manufacture of glass fibres": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.14", description: "Glass fibres" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.14", description: "Manufacture of glass fibres" },
            UK_SIC: { code: "23.14", description: "Manufacture of glass fibres" },
        },
        "Manufacture and processing of other glass, including technical glassware": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.15", description: "Other processed glass" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.15", description: "Manufacture and processing of other glass, including technical glassware" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of refractory products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.20", description: "Refractory products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.20", description: "Manufacture of refractory products" },
            UK_SIC: { code: "23.20", description: "Manufacture of refractory products" },
        },
        "Manufacture of ceramic tiles and flags": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.31", description: "Ceramic tiles and flags" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.31", description: "Manufacture of ceramic tiles and flags" },
            UK_SIC: { code: "23.31", description: "Manufacture of ceramic tiles and flags" },
        },
        "Manufacture of bricks, tiles and construction products, in baked clay": {
            SCAT: { code: "32", description: "Brickworks (Traditional), Clay Tile/Pipe Works" },
            CPA: { code: "23.32", description: "Bricks, tiles and construction products, in baked clay" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.32", description: "Manufacture of bricks, tiles and construction products, in baked clay" },
            UK_SIC: { code: "23.32", description: "Manufacture of bricks, tiles and construction products, in baked clay" },
        },
        "Manufacture of ceramic household and ornamental articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.41", description: "Ceramic household and ornamental articles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.41", description: "Manufacture of ceramic household and ornamental articles" },
            UK_SIC: { code: "23.41", description: "Manufacture of ceramic household and ornamental articles" },
        },
        "Manufacture of ceramic sanitary fixtures": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.42", description: "Ceramic sanitary fixtures" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.42", description: "Manufacture of ceramic sanitary fixtures" },
            UK_SIC: { code: "23.42", description: "Manufacture of ceramic sanitary fixtures" },
        },
        "Manufacture of ceramic insulators and insulating fittings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.43", description: "Ceramic insulators and insulating fittings" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.43", description: "Manufacture of ceramic insulators and insulating fittings" },
            UK_SIC: { code: "23.43", description: "Manufacture of ceramic insulators and insulating fittings" },
        },
        "Manufacture of other technical ceramic products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.44", description: "Other technical ceramic products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.44", description: "Manufacture of other technical ceramic products" },
            UK_SIC: { code: "23.44", description: "Manufacture of other technical ceramic products" },
        },
        "Manufacture of other ceramic products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.45", description: "Other ceramic products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.45", description: "Manufacture of other ceramic products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of cement": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.51", description: "Cement" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.51", description: "Manufacture of cement" },
            UK_SIC: { code: "23.51", description: "Manufacture of cement" },
        },
        "Manufacture of lime and plaster": {
            SCAT: { code: "17", description: "Asphalt Plants" },
            CPA: { code: "23.52", description: "Lime and plaster" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.52", description: "Manufacture of lime and plaster" },
            UK_SIC: { code: "23.52", description: "Manufacture of lime and plaster" },
        },
        "Manufacture of concrete products for construction purposes": {
            SCAT: { code: "72\n73\n71", description: "Concrete Block Works\nConcrete Product Works\nConcrete Batching Plants" },
            CPA: { code: "23.61", description: "Concrete products for construction purposes" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.61", description: "Manufacture of concrete products for construction purposes" },
            UK_SIC: { code: "23.61", description: "Manufacture of concrete products for construction purposes" },
        },
        "Manufacture of plaster products for construction purposes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.62", description: "Plaster products for construction purposes" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.62", description: "Manufacture of plaster products for construction purposes" },
            UK_SIC: { code: "23.62", description: "Manufacture of plaster products for construction purposes" },
        },
        "Manufacture of ready-mixed concrete": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.63", description: "Ready-mixed concrete" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.63", description: "Manufacture of ready-mixed concrete" },
            UK_SIC: { code: "23.63", description: "Manufacture of ready-mixed concrete" },
        },
        "Manufacture of mortars": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.64", description: "Mortars" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.64", description: "Manufacture of mortars" },
            UK_SIC: { code: "23.64", description: "Manufacture of mortars" },
        },
        "Manufacture of fibre cement": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.65", description: "Articles of fibre cement" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.65", description: "Manufacture of fibre cement" },
            UK_SIC: { code: "23.65", description: "Manufacture of fibre cement" },
        },
        "Manufacture of other articles of concrete, cement and plaster": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.66", description: "Other articles of concrete, cement and plaster" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.66", description: "Manufacture of other articles of concrete, cement and plaster" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Cutting, shaping and finishing of stone": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.70", description: "Cut, shaped and finished stone" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "23.70", description: "Cutting, shaping and finishing of stone" },
            UK_SIC: { code: "23.70", description: "Cutting, shaping and finishing of stone" },
        },
        "Manufacture of abrasive products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "23.91", description: "Abrasive products" },
            ISIC: { code: "2391", description: "Manufacture of refractory products" },
            NACE: { code: "23.91", description: "Manufacture of abrasive products" },
            UK_SIC: { code: "23.91", description: "Production of abrasive products" },
        },
        "Manufacture of other non-metallic mineral products not elsewhere classified": {
            SCAT: { code: "166\n167\n168\n169\n170\n171\n172\n173\n174\n175\n176\n177\n178\n179\n180\n181\n182\n183\n184\n185\n186", description: "Mineral Depot and Premises\nMineral Producing Hereditament - Blockstone\nMineral Producing Hereditament - Brine\nMineral Producing Hereditament - Chalk\nMineral Producing Hereditament - China Clay\nMineral Producing Hereditament - Clay\nMineral Producing Hereditament - Coal\nMineral Producing Hereditament - Fluorspar\nMineral Producing Hereditament - Gas\nMineral Producing Hereditament - Hardrock\nMineral Producing Hereditament - Inert\nMineral Producing Hereditament - Oil\nMineral Producing Hereditament - Other Mineral Category\nMineral Producing Hereditament - Putrescible\nMineral  Producing  Hereditament  - Sand and Gravel\nMineral Producing Hereditament - Sand\nMineral Producing Hereditament - Shale Unburnt\nMineral Producing Hereditament - Slate\nMineral Producing Hereditament With Batching Plant\nMineral Producing Hereditament With Coating Plant\nMineral Producing Heredtament With Tunnel Kiln" },
            CPA: { code: "23.99", description: "Other non-metallic mineral products n.e.c." },
            ISIC: { code: "2399", description: "Manufacture of other non-metallic mineral products not elsewhere classified" },
            NACE: { code: "23.99", description: "Manufacture of other non-metallic mineral products not elsewhere classified" },
            UK_SIC: { code: "23.99", description: "Manufacture of other non-metallic mineral products n.e.c." },
        },
        "Manufacture of basic iron and steel and of ferro-alloys": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.10", description: "Basic iron, steel and ferro-alloys" },
            ISIC: { code: "2410", description: "Manufacture of basic iron and steel" },
            NACE: { code: "24.10", description: "Manufacture of basic iron and steel and of ferro-alloys" },
            UK_SIC: { code: "24.10", description: "Manufacture of basic iron and steel and of ferro-alloys" },
        },
        "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.20", description: "Tubes, pipes, hollow profiles and related fittings, of iron or steel" },
            ISIC: { code: "2420", description: "Manufacture of basic precious and other non-ferrous metals" },
            NACE: { code: "24.20", description: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel" },
            UK_SIC: { code: "24.20", description: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel" },
        },
        "Cold drawing of bars": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.31", description: "Cold drawn bars" },
            ISIC: { code: "2431", description: "Casting of iron and steel" },
            NACE: { code: "24.31", description: "Cold drawing of bars" },
            UK_SIC: { code: "24.31", description: "Cold drawing of bars" },
        },
        "Cold rolling of narrow strip": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.32", description: "Cold rolled narrow strip" },
            ISIC: { code: "2432", description: "Casting of non-ferrous metals" },
            NACE: { code: "24.32", description: "Cold rolling of narrow strip" },
            UK_SIC: { code: "24.32", description: "Cold rolling of narrow strip" },
        },
        "Cold forming or folding": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.33", description: "Cold formed or folded products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.33", description: "Cold forming or folding" },
            UK_SIC: { code: "24.33", description: "Cold forming or folding" },
        },
        "Cold drawing of wire": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.34", description: "Cold drawn wire" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.34", description: "Cold drawing of wire" },
            UK_SIC: { code: "24.34", description: "Cold drawing of wire" },
        },
        "Precious metals production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.41", description: "Precious metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.41", description: "Precious metals production" },
            UK_SIC: { code: "24.41", description: "Precious metals production" },
        },
        "Aluminium production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.42", description: "Aluminium" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.42", description: "Aluminium production" },
            UK_SIC: { code: "24.42", description: "Aluminium production" },
        },
        "Lead, zinc and tin production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.43", description: "Lead, zinc and tin" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.43", description: "Lead, zinc and tin production" },
            UK_SIC: { code: "24.43", description: "Lead, zinc and tin production" },
        },
        "Copper production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.44", description: "Copper" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.44", description: "Copper production" },
            UK_SIC: { code: "24.44", description: "Copper production" },
        },
        "Other non-ferrous metal production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.45", description: "Other non-ferrous metal" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.45", description: "Other non-ferrous metal production" },
            UK_SIC: { code: "24.45", description: "Other non-ferrous metal production" },
        },
        "Processing of nuclear fuel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.46", description: "Natural uranium and its compounds; alloys, dispersions, ceramic products and mixtures containing natural uranium or natural uranium compounds" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.46", description: "Processing of nuclear fuel" },
            UK_SIC: { code: "24.46", description: "Processing of nuclear fuel" },
        },
        "Casting of iron": {
            SCAT: { code: "110", description: "Foundries" },
            CPA: { code: "24.51", description: "Casting services of iron" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.51", description: "Casting of iron" },
            UK_SIC: { code: "24.51", description: "Casting of iron" },
        },
        "Casting of steel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.52", description: "Casting services of steel; tubes, pipes and pipe fittings of cast steel" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.52", description: "Casting of steel" },
            UK_SIC: { code: "24.52", description: "Casting of steel" },
        },
        "Casting of light metals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.53", description: "Casting services of light metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.53", description: "Casting of light metals" },
            UK_SIC: { code: "24.53", description: "Casting of light metals" },
        },
        "Casting of other non-ferrous metals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "24.54", description: "Casting services of copper, zinc and other non-ferrous metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "24.54", description: "Casting of other non-ferrous metals" },
            UK_SIC: { code: "24.54", description: "Casting of other non-ferrous metals" },
        },
        "Manufacture of metal structures and parts of structures": {
            SCAT: { code: "245", description: "Sea Dredged Aggregate Processing Plants & Depots" },
            CPA: { code: "25.11", description: "Metal structures and parts of structures" },
            ISIC: { code: "2511", description: "Manufacture of structural metal products" },
            NACE: { code: "25.11", description: "Manufacture of metal structures and parts of structures" },
            UK_SIC: { code: "25.11", description: "Manufacture of metal structures and parts of structures" },
        },
        "Manufacture of doors and windows of metal": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.12", description: "Doors, windows and their frames and thresholds for doors, of metal" },
            ISIC: { code: "2512", description: "Manufacture of tanks, reservoirs and containers of metal" },
            NACE: { code: "25.12", description: "Manufacture of doors and windows of metal" },
            UK_SIC: { code: "25.12", description: "Manufacture of doors and windows of metal" },
        },
        "Manufacture of central heating radiators, steam generators and boilers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.21", description: "Central heating radiators, steam generators and boilers" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.21", description: "Manufacture of central heating radiators, steam generators and boilers" },
            UK_SIC: { code: "25.21", description: "Manufacture of central heating radiators and boilers" },
        },
        "Manufacture of other tanks, reservoirs and containers of metal": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.22", description: "Other tanks, reservoirs and containers of metal" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.22", description: "Manufacture of other tanks, reservoirs and containers of metal" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of weapons and ammunition": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.30", description: "Weapons and ammunition" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.30", description: "Manufacture of weapons and ammunition" },
            UK_SIC: { code: "25.30", description: "Manufacture of steam generators, except central heating hot water boilers" },
        },
        "Forging and shaping metal and powder metallurgy": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.40", description: "Forging and shaping services of metal; powder metallurgy" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.40", description: "Forging and shaping metal and powder metallurgy" },
            UK_SIC: { code: "25.40", description: "Manufacture of weapons and ammunition" },
        },
        "Coating of metals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.51", description: "Coating services of metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.51", description: "Coating of metals" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Heat treatment of metals": {
            SCAT: { code: "9", description: "Aluminum Smelting Works" },
            CPA: { code: "25.52", description: "Heat treatment services of metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.52", description: "Heat treatment of metals" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Machining of metals": {
            SCAT: { code: "142", description: "Iron and/or Steel Works" },
            CPA: { code: "25.53", description: "Machining services of metals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.53", description: "Machining of metals" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of cutlery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.61", description: "Cutlery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.61", description: "Manufacture of cutlery" },
            UK_SIC: { code: "25.61", description: "Treatment and coating of metals" },
        },
        "Manufacture of locks and hinges": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.62", description: "Locks and hinges" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.62", description: "Manufacture of locks and hinges" },
            UK_SIC: { code: "25.62", description: "Machining" },
        },
        "Manufacture of tools": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.63", description: "Tools" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.63", description: "Manufacture of tools" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of steel drums and similar containers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.91", description: "Steel drums and similar containers" },
            ISIC: { code: "2591", description: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy" },
            NACE: { code: "25.91", description: "Manufacture of steel drums and similar containers" },
            UK_SIC: { code: "25.91", description: "Manufacture of steel drums and similar containers" },
        },
        "Manufacture of light metal packaging": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.92", description: "Light metal packaging" },
            ISIC: { code: "2592", description: "Treatment and coating of metals; machining" },
            NACE: { code: "25.92", description: "Manufacture of light metal packaging" },
            UK_SIC: { code: "25.92", description: "Manufacture of light metal packaging" },
        },
        "Manufacture of wire products, chain and springs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.93", description: "Wire products, chain and springs" },
            ISIC: { code: "2593", description: "Manufacture of cutlery, hand tools and general hardware" },
            NACE: { code: "25.93", description: "Manufacture of wire products, chain and springs" },
            UK_SIC: { code: "25.93", description: "Manufacture of wire products, chain and springs" },
        },
        "Manufacture of fasteners and screw machine products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.94", description: "Fasteners and screw machine products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "25.94", description: "Manufacture of fasteners and screw machine products" },
            UK_SIC: { code: "25.94", description: "Manufacture of fasteners and screw machine products" },
        },
        "Manufacture of other fabricated metal products not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "25.99", description: "Other fabricated metal products n.e.c." },
            ISIC: { code: "2599", description: "Manufacture of other fabricated metal products not elsewhere classified" },
            NACE: { code: "25.99", description: "Manufacture of other fabricated metal products not elsewhere classified" },
            UK_SIC: { code: "25.99", description: "Manufacture of other fabricated metal products n.e.c." },
        },
        "Manufacture of electronic components": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.11", description: "Electronic components" },
            ISIC: { code: "2611", description: "Manufacture of solar cells, solar panels and photovoltaic inverters" },
            NACE: { code: "26.11", description: "Manufacture of electronic components" },
            UK_SIC: { code: "26.11", description: "Manufacture of electronic components" },
        },
        "Manufacture of loaded electronic boards": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.12", description: "Loaded electronic boards" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "26.12", description: "Manufacture of loaded electronic boards" },
            UK_SIC: { code: "26.12", description: "Manufacture of loaded electronic boards" },
        },
        "Manufacture of computers and peripheral equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.20", description: "Computers and peripheral equipment" },
            ISIC: { code: "2620", description: "Manufacture of computers and peripheral equipment" },
            NACE: { code: "26.20", description: "Manufacture of computers and peripheral equipment" },
            UK_SIC: { code: "26.20", description: "Manufacture of computers and peripheral equipment" },
        },
        "Manufacture of communication equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.30", description: "Communication equipment" },
            ISIC: { code: "2630", description: "Manufacture of communication equipment" },
            NACE: { code: "26.30", description: "Manufacture of communication equipment" },
            UK_SIC: { code: "26.30", description: "Manufacture of communication equipment" },
        },
        "Manufacture of consumer electronics": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.40", description: "Consumer electronics" },
            ISIC: { code: "2640", description: "Manufacture of consumer electronics" },
            NACE: { code: "26.40", description: "Manufacture of consumer electronics" },
            UK_SIC: { code: "26.40", description: "Manufacture of consumer electronics" },
        },
        "Manufacture of instruments and appliances for measuring, testing and navigation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.51", description: "Measuring, testing and navigation equipment" },
            ISIC: { code: "2651", description: "Manufacture of measuring, testing, navigating and control equipment" },
            NACE: { code: "26.51", description: "Manufacture of instruments and appliances for measuring, testing and navigation" },
            UK_SIC: { code: "26.51", description: "Manufacture of instruments and appliances for measuring, testing and navigation" },
        },
        "Manufacture of watches and clocks": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.52", description: "Watches and clocks" },
            ISIC: { code: "2652", description: "Manufacture of watches and clocks" },
            NACE: { code: "26.52", description: "Manufacture of watches and clocks" },
            UK_SIC: { code: "26.52", description: "Manufacture of watches and clocks" },
        },
        "Manufacture of irradiation, electromedical and electrotherapeutic equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.60", description: "Irradiation, electromedical and electrotherapeutic equipment" },
            ISIC: { code: "2660", description: "Manufacture of irradiation, electromedical and electrotherapeutic equipment" },
            NACE: { code: "26.60", description: "Manufacture of irradiation, electromedical and electrotherapeutic equipment" },
            UK_SIC: { code: "26.60", description: "Manufacture of irradiation, electromedical and electrotherapeutic equipment" },
        },
        "Manufacture of optical instruments, magnetic and optical media and photographic equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "26.70", description: "Optical instruments, magnetic and optical media and photographic equipment" },
            ISIC: { code: "2670", description: "Manufacture of optical instruments and photographic equipment" },
            NACE: { code: "26.70", description: "Manufacture of optical instruments, magnetic and optical media and photographic equipment" },
            UK_SIC: { code: "26.70", description: "Manufacture of optical instruments and photographic equipment" },
        },
        "Manufacture of electric motors, generators and transformers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.11", description: "Electric motors, generators and transformers" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "27.11", description: "Manufacture of electric motors, generators and transformers" },
            UK_SIC: { code: "27.11", description: "Manufacture of electric motors, generators and transformers" },
        },
        "Manufacture of electricity distribution and control apparatus": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.12", description: "Electricity distribution and control apparatus" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "27.12", description: "Manufacture of electricity distribution and control apparatus" },
            UK_SIC: { code: "27.12", description: "Manufacture of electricity distribution and control apparatus" },
        },
        "Manufacture of batteries and accumulators": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.20", description: "Batteries and accumulators" },
            ISIC: { code: "2720", description: "Manufacture of batteries and accumulators" },
            NACE: { code: "27.20", description: "Manufacture of batteries and accumulators" },
            UK_SIC: { code: "27.20", description: "Manufacture of batteries and accumulators" },
        },
        "Manufacture of fibre optic cables": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.31", description: "Fibre optic cables" },
            ISIC: { code: "2731", description: "Manufacture of fibre optic cables" },
            NACE: { code: "27.31", description: "Manufacture of fibre optic cables" },
            UK_SIC: { code: "27.31", description: "Manufacture of fibre optic cables" },
        },
        "Manufacture of other electronic and electric wires and cables": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.32", description: "Other electronic and electric wires and cables" },
            ISIC: { code: "2732", description: "Manufacture of other electronic and electric wires and cables" },
            NACE: { code: "27.32", description: "Manufacture of other electronic and electric wires and cables" },
            UK_SIC: { code: "27.32", description: "Manufacture of other electronic and electric wires and cables" },
        },
        "Manufacture of wiring devices": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.33", description: "Wiring devices" },
            ISIC: { code: "2733", description: "Manufacture of wiring devices" },
            NACE: { code: "27.33", description: "Manufacture of wiring devices" },
            UK_SIC: { code: "27.33", description: "Manufacture of wiring devices" },
        },
        "Manufacture of lighting equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.40", description: "Lighting equipment" },
            ISIC: { code: "2740", description: "Manufacture of lighting equipment" },
            NACE: { code: "27.40", description: "Manufacture of lighting equipment" },
            UK_SIC: { code: "27.40", description: "Manufacture of electric lighting equipment" },
        },
        "Manufacture of electric domestic appliances": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.51", description: "Electric domestic appliances" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "27.51", description: "Manufacture of electric domestic appliances" },
            UK_SIC: { code: "27.51", description: "Manufacture of electric domestic appliances" },
        },
        "Manufacture of non-electric domestic appliances": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.52", description: "Non-electric domestic appliances" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "27.52", description: "Manufacture of non-electric domestic appliances" },
            UK_SIC: { code: "27.52", description: "Manufacture of non-electric domestic appliances" },
        },
        "Manufacture of other electrical equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "27.90", description: "Other electrical equipment" },
            ISIC: { code: "2790", description: "Manufacture of other electrical equipment" },
            NACE: { code: "27.90", description: "Manufacture of other electrical equipment" },
            UK_SIC: { code: "27.90", description: "Manufacture of other electrical equipment" },
        },
        "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.11", description: "Engines and turbines, except aircraft, vehicle and cycle engines" },
            ISIC: { code: "2811", description: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines" },
            NACE: { code: "28.11", description: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines" },
            UK_SIC: { code: "28.11", description: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines" },
        },
        "Manufacture of fluid power equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.12", description: "Fluid power equipment" },
            ISIC: { code: "2812", description: "Manufacture of fluid power equipment" },
            NACE: { code: "28.12", description: "Manufacture of fluid power equipment" },
            UK_SIC: { code: "28.12", description: "Manufacture of fluid power equipment" },
        },
        "Manufacture of other pumps and compressors": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.13", description: "Other pumps and compressors" },
            ISIC: { code: "2813", description: "Manufacture of other pumps, compressors, taps and valves" },
            NACE: { code: "28.13", description: "Manufacture of other pumps and compressors" },
            UK_SIC: { code: "28.13", description: "Manufacture of other pumps and compressors" },
        },
        "Manufacture of other taps and valves": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.14", description: "Other taps and valves" },
            ISIC: { code: "2814", description: "Manufacture of bearings, gears, gearing and driving elements" },
            NACE: { code: "28.14", description: "Manufacture of other taps and valves" },
            UK_SIC: { code: "28.14", description: "Manufacture of other taps and valves" },
        },
        "Manufacture of bearings, gears, gearing and driving elements": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.15", description: "Bearings, gears, gearing and driving elements" },
            ISIC: { code: "2815", description: "Manufacture of ovens, furnaces and permanent household heating equipment" },
            NACE: { code: "28.15", description: "Manufacture of bearings, gears, gearing and driving elements" },
            UK_SIC: { code: "28.15", description: "Manufacture of bearings, gears, gearing and driving elements" },
        },
        "Manufacture of ovens, furnaces and permanent household heating equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.21", description: "Ovens, furnaces and permanent household heating equipment" },
            ISIC: { code: "2821", description: "Manufacture of agricultural and forestry machinery" },
            NACE: { code: "28.21", description: "Manufacture of ovens, furnaces and permanent household heating equipment" },
            UK_SIC: { code: "28.21", description: "Manufacture of ovens, furnaces and furnace burners" },
        },
        "Manufacture of lifting and handling equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.22", description: "Lifting and handling equipment" },
            ISIC: { code: "2822", description: "Manufacture of metal-forming machinery and machine tools" },
            NACE: { code: "28.22", description: "Manufacture of lifting and handling equipment" },
            UK_SIC: { code: "28.22", description: "Manufacture of lifting and handling equipment" },
        },
        "Manufacture of office machinery and equipment, except computers and peripheral equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.23", description: "Office machinery and equipment, except computers and peripheral equipment" },
            ISIC: { code: "2823", description: "Manufacture of machinery for metallurgy" },
            NACE: { code: "28.23", description: "Manufacture of office machinery and equipment, except computers and peripheral equipment" },
            UK_SIC: { code: "28.23", description: "Manufacture of office machinery and equipment (except computers and peripheral equipment)" },
        },
        "Manufacture of power-driven hand tools": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.24", description: "Power-driven hand tools" },
            ISIC: { code: "2824", description: "Manufacture of machinery for mining, quarrying and construction" },
            NACE: { code: "28.24", description: "Manufacture of power-driven hand tools" },
            UK_SIC: { code: "28.24", description: "Manufacture of power-driven hand tools" },
        },
        "Manufacture of non-domestic air conditioning equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.25", description: "Non-domestic air conditioning equipment" },
            ISIC: { code: "2825", description: "Manufacture of machinery for food, beverage and tobacco processing" },
            NACE: { code: "28.25", description: "Manufacture of non-domestic air conditioning equipment" },
            UK_SIC: { code: "28.25", description: "Manufacture of non-domestic cooling and ventilation equipment" },
        },
        "Manufacture of other general-purpose machinery not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.29", description: "Other general-purpose machinery n.e.c." },
            ISIC: { code: "2829", description: "Manufacture of other special-purpose machinery" },
            NACE: { code: "28.29", description: "Manufacture of other general-purpose machinery not elsewhere classified" },
            UK_SIC: { code: "28.29", description: "Manufacture of other general-purpose machinery n.e.c." },
        },
        "Manufacture of agricultural and forestry machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.30", description: "Agricultural and forestry machinery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.30", description: "Manufacture of agricultural and forestry machinery" },
            UK_SIC: { code: "28.30", description: "Manufacture of agricultural and forestry machinery" },
        },
        "Manufacture of metal forming machinery and machine tools for metal work": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.41", description: "Metal forming machinery and machine tools for metal work" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.41", description: "Manufacture of metal forming machinery and machine tools for metal work" },
            UK_SIC: { code: "28.41", description: "Manufacture of metal forming machinery" },
        },
        "Manufacture of other machine tools": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.42", description: "Other machine tools" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.42", description: "Manufacture of other machine tools" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of machinery for metallurgy": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.91", description: "Machinery for metallurgy and parts thereof" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.91", description: "Manufacture of machinery for metallurgy" },
            UK_SIC: { code: "28.91", description: "Manufacture of machinery for metallurgy" },
        },
        "Manufacture of machinery for mining, quarrying and construction": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.92", description: "Machinery for mining, quarrying and construction" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.92", description: "Manufacture of machinery for mining, quarrying and construction" },
            UK_SIC: { code: "28.92", description: "Manufacture of machinery for mining, quarrying and construction" },
        },
        "Manufacture of machinery for food, beverage and tobacco processing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.93", description: "Machinery for food, beverage and tobacco processing" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.93", description: "Manufacture of machinery for food, beverage and tobacco processing" },
            UK_SIC: { code: "28.93", description: "Manufacture of machinery for food, beverage and tobacco processing" },
        },
        "Manufacture of machinery for textile, apparel and leather production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.94", description: "Machinery for textile, apparel and leather production" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.94", description: "Manufacture of machinery for textile, apparel and leather production" },
            UK_SIC: { code: "28.94", description: "Manufacture of machinery for textile, apparel and leather production" },
        },
        "Manufacture of machinery for paper and paperboard production": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.95", description: "Machinery for paper and paperboard production and parts thereof" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.95", description: "Manufacture of machinery for paper and paperboard production" },
            UK_SIC: { code: "28.95", description: "Manufacture of machinery for paper and paperboard production" },
        },
        "Manufacture of plastics and rubber machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.96", description: "Plastics and rubber machinery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.96", description: "Manufacture of plastics and rubber machinery" },
            UK_SIC: { code: "28.96", description: "Manufacture of plastics and rubber machinery" },
        },
        "Manufacture of additive manufacturing machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.97", description: "Additive manufacturing machinery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.97", description: "Manufacture of additive manufacturing machinery" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of other special-purpose machinery not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "28.99", description: "Other special-purpose machinery n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "28.99", description: "Manufacture of other special-purpose machinery not elsewhere classified" },
            UK_SIC: { code: "28.99", description: "Manufacture of other special-purpose machinery n.e.c." },
        },
        "Manufacture of motor vehicles": {
            SCAT: { code: "192", description: "Motor Vehicle Works" },
            CPA: { code: "29.10", description: "Motor vehicles" },
            ISIC: { code: "2910", description: "Manufacture of motor vehicles" },
            NACE: { code: "29.10", description: "Manufacture of motor vehicles" },
            UK_SIC: { code: "29.10", description: "Manufacture of motor vehicles" },
        },
        "Manufacture of bodies and coachwork for motor vehicles; manufacture of trailers and semi-trailers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "29.20", description: "Bodies and coachwork for motor vehicles; trailers and semi-trailers" },
            ISIC: { code: "2920", description: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers" },
            NACE: { code: "29.20", description: "Manufacture of bodies and coachwork for motor vehicles; manufacture of trailers and semi-trailers" },
            UK_SIC: { code: "29.20", description: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers" },
        },
        "Manufacture of electrical and electronic equipment for motor vehicles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "29.31", description: "Electrical and electronic equipment for motor vehicles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "29.31", description: "Manufacture of electrical and electronic equipment for motor vehicles" },
            UK_SIC: { code: "29.31", description: "Manufacture of electrical and electronic equipment for motor vehicles" },
        },
        "Manufacture of other parts and accessories for motor vehicles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "29.32", description: "Other parts and accessories for motor vehicles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "29.32", description: "Manufacture of other parts and accessories for motor vehicles" },
            UK_SIC: { code: "29.32", description: "Manufacture of other parts and accessories for motor vehicles" },
        },
        "Building of civilian ships and floating structures": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.11", description: "Civilian ships and floating structures" },
            ISIC: { code: "3011", description: "Building of ships and floating structures" },
            NACE: { code: "30.11", description: "Building of civilian ships and floating structures" },
            UK_SIC: { code: "30.11", description: "Building of ships and floating structures" },
        },
        "Building of pleasure and sporting boats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.12", description: "Pleasure and sporting boats" },
            ISIC: { code: "3012", description: "Building of pleasure and sporting boats" },
            NACE: { code: "30.12", description: "Building of pleasure and sporting boats" },
            UK_SIC: { code: "30.12", description: "Building of pleasure and sporting boats" },
        },
        "Building of military ships and vessels": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.13", description: "Military ships and vessels" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "30.13", description: "Building of military ships and vessels" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of railway locomotives and rolling stock": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.20", description: "Railway locomotives and rolling stock" },
            ISIC: { code: "3020", description: "Manufacture of railway locomotives and rolling stock" },
            NACE: { code: "30.20", description: "Manufacture of railway locomotives and rolling stock" },
            UK_SIC: { code: "30.20", description: "Manufacture of railway locomotives and rolling stock" },
        },
        "Manufacture of civilian air and spacecraft and related machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.31", description: "Civilian air and spacecraft and related machinery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "30.31", description: "Manufacture of civilian air and spacecraft and related machinery" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of military air and spacecraft and related machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.32", description: "Military air and spacecraft and related machinery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "30.32", description: "Manufacture of military air and spacecraft and related machinery" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of military fighting vehicles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.40", description: "Military fighting vehicles" },
            ISIC: { code: "3040", description: "Manufacture of military fighting vehicles" },
            NACE: { code: "30.40", description: "Manufacture of military fighting vehicles" },
            UK_SIC: { code: "30.40", description: "Manufacture of military fighting vehicles" },
        },
        "Manufacture of motorcycles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.91", description: "Motorcycles" },
            ISIC: { code: "3091", description: "Manufacture of motorcycles" },
            NACE: { code: "30.91", description: "Manufacture of motorcycles" },
            UK_SIC: { code: "30.91", description: "Manufacture of motorcycles" },
        },
        "Manufacture of bicycles and invalid carriages": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.92", description: "Bicycles and invalid carriages" },
            ISIC: { code: "3092", description: "Manufacture of bicycles and invalid carriages" },
            NACE: { code: "30.92", description: "Manufacture of bicycles and invalid carriages" },
            UK_SIC: { code: "30.92", description: "Manufacture of bicycles and invalid carriages" },
        },
        "Manufacture of other transport equipment not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "30.99", description: "Other transport equipment n.e.c." },
            ISIC: { code: "3099", description: "Manufacture of other transport equipment not elsewhere classified" },
            NACE: { code: "30.99", description: "Manufacture of other transport equipment not elsewhere classified" },
            UK_SIC: { code: "30.99", description: "Manufacture of other transport equipment n.e.c." },
        },
        "Manufacture of furniture": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "31.00", description: "Furniture" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "31.00", description: "Manufacture of furniture" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Striking of coins": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.11", description: "Coins" },
            ISIC: { code: "3211", description: "Manufacture of jewellery and related articles" },
            NACE: { code: "32.11", description: "Striking of coins" },
            UK_SIC: { code: "32.11", description: "Striking of coins" },
        },
        "Manufacture of jewellery and related articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.12", description: "Jewellery and related articles" },
            ISIC: { code: "3212", description: "Manufacture of imitation jewellery and related articles" },
            NACE: { code: "32.12", description: "Manufacture of jewellery and related articles" },
            UK_SIC: { code: "32.12", description: "Manufacture of jewellery and related articles" },
        },
        "Manufacture of imitation jewellery and related articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.13", description: "Imitation jewellery and related articles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "32.13", description: "Manufacture of imitation jewellery and related articles" },
            UK_SIC: { code: "32.13", description: "Manufacture of imitation jewellery and related articles" },
        },
        "Manufacture of musical instruments": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.20", description: "Musical instruments" },
            ISIC: { code: "3220", description: "Manufacture of musical instruments" },
            NACE: { code: "32.20", description: "Manufacture of musical instruments" },
            UK_SIC: { code: "32.20", description: "Manufacture of musical instruments" },
        },
        "Manufacture of sports goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.30", description: "Sports goods" },
            ISIC: { code: "3230", description: "Manufacture of sports goods" },
            NACE: { code: "32.30", description: "Manufacture of sports goods" },
            UK_SIC: { code: "32.30", description: "Manufacture of sports goods" },
        },
        "Manufacture of games and toys": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.40", description: "Games and toys" },
            ISIC: { code: "3240", description: "Manufacture of games and toys" },
            NACE: { code: "32.40", description: "Manufacture of games and toys" },
            UK_SIC: { code: "32.40", description: "Manufacture of games and toys" },
        },
        "Manufacture of medical and dental instruments and supplies": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.50", description: "Medical and dental instruments and supplies" },
            ISIC: { code: "3250", description: "Manufacture of medical and dental instruments and supplies" },
            NACE: { code: "32.50", description: "Manufacture of medical and dental instruments and supplies" },
            UK_SIC: { code: "32.50", description: "Manufacture of medical and dental instruments and supplies" },
        },
        "Manufacture of brooms and brushes": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "32.91", description: "Brooms and brushes" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "32.91", description: "Manufacture of brooms and brushes" },
            UK_SIC: { code: "32.91", description: "Manufacture of brooms and brushes" },
        },
        "Other manufacturing not elsewhere classified": {
            SCAT: { code: "20", description: "Baling Plants" },
            CPA: { code: "32.99", description: "Other manufactured goods n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "32.99", description: "Other manufacturing not elsewhere classified" },
            UK_SIC: { code: "32.99", description: "Other manufacturing n.e.c." },
        },
        "Repair and maintenance of fabricated metal products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.11", description: "Repair and maintenance services of fabricated metal products" },
            ISIC: { code: "3311", description: "Repair and maintenance of fabricated metal products" },
            NACE: { code: "33.11", description: "Repair and maintenance of fabricated metal products" },
            UK_SIC: { code: "33.11", description: "Repair of fabricated metal products" },
        },
        "Repair and maintenance of machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.12", description: "Repair and maintenance services of machinery" },
            ISIC: { code: "3312", description: "Repair and maintenance of machinery" },
            NACE: { code: "33.12", description: "Repair and maintenance of machinery" },
            UK_SIC: { code: "33.12", description: "Repair of machinery" },
        },
        "Repair and maintenance of electronic and optical equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.13", description: "Repair and maintenance services of electronic and optical equipment" },
            ISIC: { code: "3313", description: "Repair and maintenance of electronic and optical equipment" },
            NACE: { code: "33.13", description: "Repair and maintenance of electronic and optical equipment" },
            UK_SIC: { code: "33.13", description: "Repair of electronic and optical equipment" },
        },
        "Repair and maintenance of electrical equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.14", description: "Repair and maintenance services of electrical equipment" },
            ISIC: { code: "3314", description: "Repair and maintenance of electrical equipment" },
            NACE: { code: "33.14", description: "Repair and maintenance of electrical equipment" },
            UK_SIC: { code: "33.14", description: "Repair of electrical equipment" },
        },
        "Repair and maintenance of civilian ships and boats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.15", description: "Repair and maintenance services of civilian ships and boats" },
            ISIC: { code: "3315", description: "Repair and maintenance of transport equipment, except motor vehicles" },
            NACE: { code: "33.15", description: "Repair and maintenance of civilian ships and boats" },
            UK_SIC: { code: "33.15", description: "Repair and maintenance of ships and boats" },
        },
        "Repair and maintenance of civilian air and spacecraft": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.16", description: "Repair and maintenance services of civilian air and spacecraft" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "33.16", description: "Repair and maintenance of civilian air and spacecraft" },
            UK_SIC: { code: "33.16", description: "Repair and maintenance of aircraft and spacecraft" },
        },
        "Repair and maintenance of other civilian transport equipment": {
            SCAT: { code: "714", description: "Rail Maintenance Depots" },
            CPA: { code: "33.17", description: "Repair and maintenance services of other civilian transport equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "33.17", description: "Repair and maintenance of other civilian transport equipment" },
            UK_SIC: { code: "33.17", description: "Repair and maintenance of other transport equipment" },
        },
        "Repair and maintenance of military fighting vehicles, ships, boats, air and spacecraft": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.18", description: "Repair and maintenance services of military fighting vehicles, ships, boats, air and spacecraft" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "33.18", description: "Repair and maintenance of military fighting vehicles, ships, boats, air and spacecraft" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Repair and maintenance of other equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.19", description: "Repair and maintenance services of other equipment" },
            ISIC: { code: "3319", description: "Repair and maintenance of other equipment" },
            NACE: { code: "33.19", description: "Repair and maintenance of other equipment" },
            UK_SIC: { code: "33.19", description: "Repair of other equipment" },
        },
        "Installation of industrial machinery and equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "33.20", description: "Installation services of industrial machinery and equipment" },
            ISIC: { code: "3320", description: "Installation of industrial machinery and equipment" },
            NACE: { code: "33.20", description: "Installation of industrial machinery and equipment" },
            UK_SIC: { code: "33.20", description: "Installation of industrial machinery and equipment" },
        },
        "Production of electricity from non-renewable sources": {
            SCAT: { code: "149", description: "Landfill Gas Generator Sites" },
            CPA: { code: "35.11", description: "Electricity from non-renewable sources" },
            ISIC: { code: "3511", description: "Electric power generation activities from non-renewable sources" },
            NACE: { code: "35.11", description: "Production of electricity from non-renewable sources" },
            UK_SIC: { code: "35.11", description: "Production of electricity" },
        },
        "Production of electricity from renewable sources": {
            SCAT: { code: "729\n743\n744\n745\n746", description: "Renewable Generators - Mixed Technologies\nRenewable Power Generators - Photovoltaic\nRenewable Power Generators – Wind\nRenewable Power Generators – Other\nRenewable Power Generators - Hydro" },
            CPA: { code: "35.12", description: "Electricity from renewable sources" },
            ISIC: { code: "3512", description: "Electric power generation activities from renewable sources" },
            NACE: { code: "35.12", description: "Production of electricity from renewable sources" },
            UK_SIC: { code: "35.12", description: "Transmission of electricity" },
        },
        "Transmission of electricity": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "35.13", description: "Transmission services of electricity" },
            ISIC: { code: "3513", description: "Electric power transmission and distribution activities" },
            NACE: { code: "35.13", description: "Transmission of electricity" },
            UK_SIC: { code: "35.13", description: "Distribution of electricity" },
        },
        "Distribution of electricity": {
            SCAT: { code: "94", description: "Electricity Undertakings (Non- Statutory)" },
            CPA: { code: "35.14", description: "Distribution services of electricity" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.14", description: "Distribution of electricity" },
            UK_SIC: { code: "35.14", description: "Trade of electricity" },
        },
        "Trade of electricity": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "35.15", description: "Trade services of electricity" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.15", description: "Trade of electricity" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Storage of electricity": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "35.16", description: "Storage services of electricity" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.16", description: "Storage of electricity" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Manufacture of gas": {
            SCAT: { code: "115\n730\n731", description: "Gas Processing Plants\nBiomass Biological (Crop & Slurry Based Anaerobic Digestors, Incl Gas to Grid)\nBiomass Thermal (Incl Combustion, Gasification and Pyrolysis)" },
            CPA: { code: "35.21", description: "Manufactured gas, for energy supply" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.21", description: "Manufacture of gas" },
            UK_SIC: { code: "35.21", description: "Manufacture of gas" },
        },
        "Distribution of gaseous fuels through mains": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "35.22", description: "Distribution services of gaseous fuels through mains" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.22", description: "Distribution of gaseous fuels through mains" },
            UK_SIC: { code: "35.22", description: "Distribution of gaseous fuels through mains" },
        },
        "Trade of gas through mains": {
            SCAT: { code: "741", description: "Independent Gas Transport (IGT)" },
            CPA: { code: "35.23", description: "Trade services of gas through mains" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.23", description: "Trade of gas through mains" },
            UK_SIC: { code: "35.23", description: "Trade of gas through mains" },
        },
        "Storage of gas as part of network supply services": {
            SCAT: { code: "742\n749", description: "Independent Distribution Network Operators (IDNO)\nUnderground Gas Storage Facility" },
            CPA: { code: "35.24", description: "Storage services of gas as part of the network supply services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "35.24", description: "Storage of gas as part of network supply services" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Steam and air conditioning supply": {
            SCAT: { code: "88", description: "District Heating Undertakings & Networks" },
            CPA: { code: "35.30", description: "Steam and air conditioning supply services" },
            ISIC: { code: "3530", description: "Steam and air conditioning supply" },
            NACE: { code: "35.30", description: "Steam and air conditioning supply" },
            UK_SIC: { code: "35.30", description: "Steam and air conditioning supply" },
        },
        "Activities of brokers and agents for electric power and natural gas": {
            SCAT: { code: "720\n710", description: "LPG Service Station\nResidual Mall" },
            CPA: { code: "35.40", description: "Services of brokers and agents for electric power and natural gas" },
            ISIC: { code: "3540", description: "Activities of brokers and agents for electric power and natural gas" },
            NACE: { code: "35.40", description: "Activities of brokers and agents for electric power and natural gas" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Water collection, treatment and supply": {
            SCAT: { code: "93", description: "Mine Water Treatment Plant and Premises" },
            CPA: { code: "36.00", description: "Natural water; water treatment and supply services" },
            ISIC: { code: "3600", description: "Water collection, treatment and supply" },
            NACE: { code: "36.00", description: "Water collection, treatment and supply" },
            UK_SIC: { code: "36.00", description: "Water collection, treatment and supply" },
        },
        "Sewerage": {
            SCAT: { code: "246", description: "Sewage Works (National Scheme)" },
            CPA: { code: "37.00", description: "Sewerage services; sewage sludge" },
            ISIC: { code: "3700", description: "Sewerage" },
            NACE: { code: "37.00", description: "Sewerage" },
            UK_SIC: { code: "37.00", description: "Sewerage" },
        },
        "Collection of non-hazardous waste": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "38.11", description: "Collection services of non-hazardous waste" },
            ISIC: { code: "3811", description: "Collection of non-hazardous waste" },
            NACE: { code: "38.11", description: "Collection of non-hazardous waste" },
            UK_SIC: { code: "38.11", description: "Collection of non-hazardous waste" },
        },
        "Collection of hazardous waste": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "38.12", description: "Collection services of hazardous waste" },
            ISIC: { code: "3812", description: "Collection of hazardous waste" },
            NACE: { code: "38.12", description: "Collection of hazardous waste" },
            UK_SIC: { code: "38.12", description: "Collection of hazardous waste" },
        },
        "Materials recovery": {
            SCAT: { code: "150", description: "Land used for Waste Composting" },
            CPA: { code: "38.21", description: "Materials recovery services and secondary raw materials" },
            ISIC: { code: "3821", description: "Treatment and disposal of non-hazardous waste" },
            NACE: { code: "38.21", description: "Materials recovery" },
            UK_SIC: { code: "38.21", description: "Treatment and disposal of non-hazardous waste" },
        },
        "Energy recovery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "38.22", description: "Energy recovery services and pellets of municipal waste for incineration with energy recovery" },
            ISIC: { code: "3822", description: "Treatment and disposal of hazardous waste" },
            NACE: { code: "38.22", description: "Energy recovery" },
            UK_SIC: { code: "38.22", description: "Treatment and disposal of hazardous waste" },
        },
        "Other waste recovery": {
            SCAT: { code: "297\n298\n299\n727", description: "Waste Incinerator Plants\nWaste Recycling Plants\nWaste Transfer Stations\nWaste Anaerobic Digestion Plants" },
            CPA: { code: "38.23", description: "Other waste recovery services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "38.23", description: "Other waste recovery" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Incineration without energy recovery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "38.31", description: "Incineration services without energy recovery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "38.31", description: "Incineration without energy recovery" },
            UK_SIC: { code: "38.31", description: "Dismantling of wrecks" },
        },
        "Landfilling or permanent storage": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "38.32", description: "Landfilling or permanent storage services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "38.32", description: "Landfilling or permanent storage" },
            UK_SIC: { code: "38.32", description: "Recovery of sorted materials" },
        },
        "Other waste disposal": {
            SCAT: { code: "233", description: "Refuse Destructor Plants/Disposal Sites" },
            CPA: { code: "38.33", description: "Other waste disposal services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "38.33", description: "Other waste disposal" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Remediation activities and other waste management service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "39.00", description: "Remediation services and other waste management services" },
            ISIC: { code: "3900", description: "Remediation and other waste management service activities" },
            NACE: { code: "39.00", description: "Remediation activities and other waste management service activities" },
            UK_SIC: { code: "39.00", description: "Remediation activities and other waste management services" },
        },
        "Construction of residential and non-residential buildings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "41.00", description: "Residential and non-residential buildings, residential and non-residential buildings construction works" },
            ISIC: { code: "4100", description: "Construction of residential and non-residential buildings" },
            NACE: { code: "41.00", description: "Construction of residential and non-residential buildings" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Construction of roads and motorways": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "42.11", description: "Roads and motorways; construction works for roads and motorways" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.11", description: "Construction of roads and motorways" },
            UK_SIC: { code: "42.11", description: "Construction of roads and motorways" },
        },
        "Construction of railways and underground railways": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "42.12", description: "Railways and underground railways; construction works for railways and underground railways" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.12", description: "Construction of railways and underground railways" },
            UK_SIC: { code: "42.12", description: "Construction of railways and underground railways" },
        },
        "Construction of bridges and tunnels": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "42.13", description: "Bridges and tunnels; construction works for bridges and tunnels" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.13", description: "Construction of bridges and tunnels" },
            UK_SIC: { code: "42.13", description: "Construction of bridges and tunnels" },
        },
        "Construction of utility projects for fluids": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "42.21", description: "Constructions and construction works for utility projects for fluids" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.21", description: "Construction of utility projects for fluids" },
            UK_SIC: { code: "42.21", description: "Construction of utility projects for fluids" },
        },
        "Construction of utility projects for electricity and telecommunications": {
            SCAT: { code: "275", description: "Telecommunications Cable Networks (National Scheme)" },
            CPA: { code: "42.22", description: "Constructions and construction works for utility projects for electricity and telecommunications" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.22", description: "Construction of utility projects for electricity and telecommunications" },
            UK_SIC: { code: "42.22", description: "Construction of utility projects for electricity and telecommunications" },
        },
        "Construction of water projects": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "42.91", description: "Constructions and construction works for water projects" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.91", description: "Construction of water projects" },
            UK_SIC: { code: "42.91", description: "Construction of water projects" },
        },
        "Construction of other civil engineering projects not elsewhere classified": {
            SCAT: { code: "51\n52", description: "Cement Tile Works\nCement Works" },
            CPA: { code: "42.99", description: "Constructions and construction works for other civil engineering projects n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "42.99", description: "Construction of other civil engineering projects not elsewhere classified" },
            UK_SIC: { code: "42.99", description: "Construction of other civil engineering projects n.e.c." },
        },
        "Demolition": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.11", description: "Demolition works" },
            ISIC: { code: "4311", description: "Demolition" },
            NACE: { code: "43.11", description: "Demolition" },
            UK_SIC: { code: "43.11", description: "Demolition" },
        },
        "Site preparation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.12", description: "Site preparation works" },
            ISIC: { code: "4312", description: "Site preparation" },
            NACE: { code: "43.12", description: "Site preparation" },
            UK_SIC: { code: "43.12", description: "Site preparation" },
        },
        "Test drilling and boring": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.13", description: "Test drilling and boring works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.13", description: "Test drilling and boring" },
            UK_SIC: { code: "43.13", description: "Test drilling and boring" },
        },
        "Electrical installation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.21", description: "Electrical installation works" },
            ISIC: { code: "4321", description: "Electrical installation" },
            NACE: { code: "43.21", description: "Electrical installation" },
            UK_SIC: { code: "43.21", description: "Electrical installation" },
        },
        "Plumbing, heat and air-conditioning installation": {
            SCAT: { code: "90", description: "Domestic Fuel Installations" },
            CPA: { code: "43.22", description: "Plumbing, heat and air conditioning installation works" },
            ISIC: { code: "4322", description: "Plumbing, heat and air-conditioning installation" },
            NACE: { code: "43.22", description: "Plumbing, heat and air-conditioning installation" },
            UK_SIC: { code: "43.22", description: "Plumbing, heat and air-conditioning installation" },
        },
        "Installation of insulation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.23", description: "Installation works of insulation and waterproofing" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.23", description: "Installation of insulation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other construction installation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.24", description: "Other construction installation works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.24", description: "Other construction installation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Plastering": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.31", description: "Plastering works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.31", description: "Plastering" },
            UK_SIC: { code: "43.31", description: "Plastering" },
        },
        "Joinery installation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.32", description: "Joinery installation works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.32", description: "Joinery installation" },
            UK_SIC: { code: "43.32", description: "Joinery installation" },
        },
        "Floor and wall covering": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.33", description: "Floor and wall covering works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.33", description: "Floor and wall covering" },
            UK_SIC: { code: "43.33", description: "Floor and wall covering" },
        },
        "Painting and glazing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.34", description: "Painting and glazing works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.34", description: "Painting and glazing" },
            UK_SIC: { code: "43.34", description: "Painting and glazing" },
        },
        "Other building completion and finishing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.35", description: "Other building completion and finishing works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.35", description: "Other building completion and finishing" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Roofing activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.41", description: "Roofing works and roof waterproofing works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.41", description: "Roofing activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other specialised construction activities in construction of buildings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.42", description: "Other specialised construction works in construction of buildings" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.42", description: "Other specialised construction activities in construction of buildings" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Specialised construction activities in civil engineering": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.50", description: "Specialised construction works in civil engineering" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.50", description: "Specialised construction activities in civil engineering" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for specialised construction services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.60", description: "Intermediation services of specialised construction services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.60", description: "Intermediation service activities for specialised construction services" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Masonry and bricklaying activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "43.91", description: "Masonry and bricklaying works" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.91", description: "Masonry and bricklaying activities" },
            UK_SIC: { code: "43.91", description: "Roofing activities" },
        },
        "Other specialised construction activities not elsewhere classified": {
            SCAT: { code: "76", description: "Contractors Huts & Compounds" },
            CPA: { code: "43.99", description: "Other specialised construction works n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "43.99", description: "Other specialised construction activities not elsewhere classified" },
            UK_SIC: { code: "43.99", description: "Other specialised construction activities n.e.c." },
        },
        "Activities of agents involved in the wholesale of agricultural raw materials, live animals, textile raw materials and semi-finished goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.11", description: "Wholesale trade services on a fee or contract basis of agricultural raw materials, live animals, textile raw materials and semi-finished goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.11", description: "Activities of agents involved in the wholesale of agricultural raw materials, live animals, textile raw materials and semi-finished goods" },
            UK_SIC: { code: "46.11", description: "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods" },
        },
        "Activities of agents involved in the wholesale of fuels, ores, metals and industrial chemicals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.12", description: "Wholesale trade services on a fee or contract basis of fuels, ores, metals and industrial chemicals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.12", description: "Activities of agents involved in the wholesale of fuels, ores, metals and industrial chemicals" },
            UK_SIC: { code: "46.12", description: "Agents involved in the sale of fuels, ores, metals and industrial chemicals" },
        },
        "Activities of agents involved in the wholesale of timber and building materials": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.13", description: "Wholesale trade services on a fee or contract basis of timber and building materials" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.13", description: "Activities of agents involved in the wholesale of timber and building materials" },
            UK_SIC: { code: "46.13", description: "Agents involved in the sale of timber and building materials" },
        },
        "Activities of agents involved in the wholesale of machinery, industrial equipment, ships and aircraft": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.14", description: "Wholesale trade services on a fee or contract basis of machinery, industrial equipment, ships and aircraft" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.14", description: "Activities of agents involved in the wholesale of machinery, industrial equipment, ships and aircraft" },
            UK_SIC: { code: "46.14", description: "Agents involved in the sale of machinery, industrial equipment, ships and aircraft" },
        },
        "Activities of agents involved in the wholesale of furniture, household goods, hardware and ironmongery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.15", description: "Wholesale trade services on a fee or contract basis of furniture, household goods, hardware and ironmongery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.15", description: "Activities of agents involved in the wholesale of furniture, household goods, hardware and ironmongery" },
            UK_SIC: { code: "46.15", description: "Agents involved in the sale of furniture, household goods, hardware and ironmongery" },
        },
        "Activities of agents involved in the wholesale of textiles, clothing, fur, footwear and leather goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.16", description: "Wholesale trade services on a fee or contract basis of textiles, clothing, fur, footwear and leather goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.16", description: "Activities of agents involved in the wholesale of textiles, clothing, fur, footwear and leather goods" },
            UK_SIC: { code: "46.16", description: "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods" },
        },
        "Activities of agents involved in the wholesale of food, beverages and tobacco": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.17", description: "Wholesale trade services on a fee or contract basis of food, beverages and tobacco" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.17", description: "Activities of agents involved in the wholesale of food, beverages and tobacco" },
            UK_SIC: { code: "46.17", description: "Agents involved in the sale of food, beverages and tobacco" },
        },
        "Activities of agents involved in the wholesale of other particular products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.18", description: "Wholesale trade services on a fee or contract basis of other particular products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.18", description: "Activities of agents involved in the wholesale of other particular products" },
            UK_SIC: { code: "46.18", description: "Agents specialised in the sale of other particular products" },
        },
        "Activities of agents involved in non-specialised wholesale": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.19", description: "Wholesale trade services on a fee or contract basis of non-specialised goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.19", description: "Activities of agents involved in non-specialised wholesale" },
            UK_SIC: { code: "46.19", description: "Agents involved in the sale of a variety of goods" },
        },
        "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.21", description: "Wholesale trade services of grain, unmanufactured tobacco, seeds and animal feeds" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.21", description: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds" },
            UK_SIC: { code: "46.21", description: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds" },
        },
        "Wholesale of flowers and plants": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.22", description: "Wholesale trade services of flowers and plants" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.22", description: "Wholesale of flowers and plants" },
            UK_SIC: { code: "46.22", description: "Wholesale of flowers and plants" },
        },
        "Wholesale of live animals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.23", description: "Wholesale trade services of live animals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.23", description: "Wholesale of live animals" },
            UK_SIC: { code: "46.23", description: "Wholesale of live animals" },
        },
        "Wholesale of hides, skins and leather": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.24", description: "Wholesale trade services of hides, skins and leather" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.24", description: "Wholesale of hides, skins and leather" },
            UK_SIC: { code: "46.24", description: "Wholesale of hides, skins and leather" },
        },
        "Wholesale of fruit and vegetables": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.31", description: "Wholesale trade services of fruit and vegetables" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.31", description: "Wholesale of fruit and vegetables" },
            UK_SIC: { code: "46.31", description: "Wholesale of fruit and vegetables" },
        },
        "Wholesale of meat, meat products, fish and fish products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.32", description: "Wholesale trade services of meat, meat products, fish and fish products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.32", description: "Wholesale of meat, meat products, fish and fish products" },
            UK_SIC: { code: "46.32", description: "Wholesale of meat and meat products" },
        },
        "Wholesale of dairy products, eggs and edible oils and fats": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.33", description: "Wholesale trade services of dairy products, eggs and edible oils and fats" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.33", description: "Wholesale of dairy products, eggs and edible oils and fats" },
            UK_SIC: { code: "46.33", description: "Wholesale of dairy products, eggs and edible oils and fats" },
        },
        "Wholesale of beverages": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.34", description: "Wholesale trade services of beverages" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.34", description: "Wholesale of beverages" },
            UK_SIC: { code: "46.34", description: "Wholesale of beverages" },
        },
        "Wholesale of tobacco products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.35", description: "Wholesale trade services of tobacco products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.35", description: "Wholesale of tobacco products" },
            UK_SIC: { code: "46.35", description: "Wholesale of tobacco products" },
        },
        "Wholesale of sugar, chocolate and sugar confectionery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.36", description: "Wholesale trade services of sugar, chocolate and sugar confectionery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.36", description: "Wholesale of sugar, chocolate and sugar confectionery" },
            UK_SIC: { code: "46.36", description: "Wholesale of sugar and chocolate and sugar confectionery" },
        },
        "Wholesale of coffee, tea, cocoa and spices": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.37", description: "Wholesale trade services of coffee, tea, cocoa and spices" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.37", description: "Wholesale of coffee, tea, cocoa and spices" },
            UK_SIC: { code: "46.37", description: "Wholesale of coffee, tea, cocoa and spices" },
        },
        "Wholesale of other food": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.38", description: "Wholesale trade services of other food products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.38", description: "Wholesale of other food" },
            UK_SIC: { code: "46.38", description: "Wholesale of other food, including fish, crustaceans and molluscs" },
        },
        "Non-specialised wholesale of food, beverages and tobacco": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.39", description: "Non-specialised wholesale trade services of food, beverages and tobacco" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.39", description: "Non-specialised wholesale of food, beverages and tobacco" },
            UK_SIC: { code: "46.39", description: "Non-specialised wholesale of food, beverages and tobacco" },
        },
        "Wholesale of textiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.41", description: "Wholesale trade services of textiles" },
            ISIC: { code: "4641", description: "Wholesale of textiles, clothing and footwear" },
            NACE: { code: "46.41", description: "Wholesale of textiles" },
            UK_SIC: { code: "46.41", description: "Wholesale of textiles" },
        },
        "Wholesale of clothing and footwear": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.42", description: "Wholesale trade services of clothing and footwear" },
            ISIC: { code: "4642", description: "Wholesale of household, office and shop furniture, carpets and lighting equipment" },
            NACE: { code: "46.42", description: "Wholesale of clothing and footwear" },
            UK_SIC: { code: "46.42", description: "Wholesale of clothing and footwear" },
        },
        "Wholesale of electrical household appliances": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.43", description: "Wholesale trade services of electrical household appliances" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.43", description: "Wholesale of electrical household appliances" },
            UK_SIC: { code: "46.43", description: "Wholesale of electrical household appliances" },
        },
        "Wholesale of china and glassware and cleaning materials": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.44", description: "Wholesale trade services of china and glassware and cleaning materials" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.44", description: "Wholesale of china and glassware and cleaning materials" },
            UK_SIC: { code: "46.44", description: "Wholesale of china and glassware and cleaning materials" },
        },
        "Wholesale of perfume and cosmetics": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.45", description: "Wholesale trade services of perfume and cosmetics" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.45", description: "Wholesale of perfume and cosmetics" },
            UK_SIC: { code: "46.45", description: "Wholesale of perfume and cosmetics" },
        },
        "Wholesale of pharmaceutical and medical goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.46", description: "Wholesale trade services of pharmaceutical and medical goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.46", description: "Wholesale of pharmaceutical and medical goods" },
            UK_SIC: { code: "46.46", description: "Wholesale of pharmaceutical goods" },
        },
        "Wholesale of household, office and shop furniture, carpets and lighting equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.47", description: "Wholesale trade services of household, office and shop furniture, carpets and lighting equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.47", description: "Wholesale of household, office and shop furniture, carpets and lighting equipment" },
            UK_SIC: { code: "46.47", description: "Wholesale of furniture, carpets and lighting equipment" },
        },
        "Wholesale of watches and jewellery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.48", description: "Wholesale trade services of watches and jewellery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.48", description: "Wholesale of watches and jewellery" },
            UK_SIC: { code: "46.48", description: "Wholesale of watches and jewellery" },
        },
        "Wholesale of other household goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.49", description: "Wholesale trade services of other household goods" },
            ISIC: { code: "4649", description: "Wholesale of other household goods" },
            NACE: { code: "46.49", description: "Wholesale of other household goods" },
            UK_SIC: { code: "46.49", description: "Wholesale of other household goods" },
        },
        "Wholesale of information and communication equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.50", description: "Wholesale trade services of information and communication equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.50", description: "Wholesale of information and communication equipment" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of agricultural machinery, equipment and supplies": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.61", description: "Wholesale trade services of agricultural machinery, equipment and supplies" },
            ISIC: { code: "4661", description: "Wholesale of motor vehicles" },
            NACE: { code: "46.61", description: "Wholesale of agricultural machinery, equipment and supplies" },
            UK_SIC: { code: "46.61", description: "Wholesale of agricultural machinery, equipment and supplies" },
        },
        "Wholesale of machine tools": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.62", description: "Wholesale trade services of machine tools" },
            ISIC: { code: "4662", description: "Wholesale of motor vehicle parts and accessories" },
            NACE: { code: "46.62", description: "Wholesale of machine tools" },
            UK_SIC: { code: "46.62", description: "Wholesale of machine tools" },
        },
        "Wholesale of mining, construction and civil engineering machinery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.63", description: "Wholesale trade services of mining, construction and civil engineering machinery" },
            ISIC: { code: "4663", description: "Wholesale of motorcycles, motorcycle parts and accessories" },
            NACE: { code: "46.63", description: "Wholesale of mining, construction and civil engineering machinery" },
            UK_SIC: { code: "46.63", description: "Wholesale of mining, construction and civil engineering machinery" },
        },
        "Wholesale of other machinery and equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.64", description: "Wholesale trade services of other machinery and equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.64", description: "Wholesale of other machinery and equipment" },
            UK_SIC: { code: "46.64", description: "Wholesale of machinery for the textile industry and of sewing and knitting machines" },
        },
        "Wholesale of motor vehicles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.71", description: "Wholesale trade services of motor vehicles" },
            ISIC: { code: "4671", description: "Wholesale of solid, liquid and gaseous fuels and related products" },
            NACE: { code: "46.71", description: "Wholesale of motor vehicles" },
            UK_SIC: { code: "46.71", description: "Wholesale of solid, liquid and gaseous fuels and related products" },
        },
        "Wholesale of motor vehicle parts and accessories": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.72", description: "Wholesale trade services of motor vehicle parts and accessories" },
            ISIC: { code: "4672", description: "Wholesale of metals and metal ores" },
            NACE: { code: "46.72", description: "Wholesale of motor vehicle parts and accessories" },
            UK_SIC: { code: "46.72", description: "Wholesale of metals and metal ores" },
        },
        "Wholesale of motorcycles, motorcycle parts and accessories": {
            SCAT: { code: "738", description: "Builders Merchant" },
            CPA: { code: "46.73", description: "Wholesale trade services of motorcycles, motorcycle parts and accessories" },
            ISIC: { code: "4673", description: "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies" },
            NACE: { code: "46.73", description: "Wholesale of motorcycles, motorcycle parts and accessories" },
            UK_SIC: { code: "46.73", description: "Wholesale of wood, construction materials and sanitary equipment" },
        },
        "Wholesale of solid, liquid and gaseous fuels and related products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.81", description: "Wholesale trade services of solid, liquid and gaseous fuels and related products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.81", description: "Wholesale of solid, liquid and gaseous fuels and related products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of metals and metal ores": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.82", description: "Wholesale trade services of metals and metal ores" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.82", description: "Wholesale of metals and metal ores" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of wood, construction materials and sanitary equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.83", description: "Wholesale trade services of wood, construction materials and sanitary equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.83", description: "Wholesale of wood, construction materials and sanitary equipment" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of hardware, plumbing and heating equipment and supplies": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.84", description: "Wholesale trade services of hardware, plumbing and heating equipment and supplies" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.84", description: "Wholesale of hardware, plumbing and heating equipment and supplies" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of chemical products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.85", description: "Wholesale trade services of chemical products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.85", description: "Wholesale of chemical products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of other intermediate products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.86", description: "Wholesale trade services of other intermediate products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.86", description: "Wholesale of other intermediate products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wholesale of waste and scrap": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.87", description: "Wholesale trade services of waste and scrap" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.87", description: "Wholesale of waste and scrap" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other specialised wholesale not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "46.89", description: "Other specialised wholesale trade services n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "46.89", description: "Other specialised wholesale not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Non-specialised wholesale trade": {
            SCAT: { code: "153", description: "Large Industrials (Over 20,000m²)" },
            CPA: { code: "46.90", description: "Non-specialised wholesale trade services" },
            ISIC: { code: "4690", description: "Non-specialized wholesale trade" },
            NACE: { code: "46.90", description: "Non-specialised wholesale trade" },
            UK_SIC: { code: "46.90", description: "Non-specialised wholesale trade" },
        },
        "Non-specialised retail sale of predominately food, beverages or tobacco": {
            SCAT: { code: "106\n139\n152", description: "Convenience Store\nHypermarkets/Superstores (over 2500m²)\nLarge Food Stores (750 - 2500m²)" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "4711", description: "Non-specialized retail sale with food, beverages or tobacco predominating" },
            NACE: { code: "47.11", description: "Non-specialised retail sale of predominately food, beverages or tobacco" },
            UK_SIC: { code: "47.11", description: "Retail sale in non-specialised stores with food, beverages or tobacco predominating" },
        },
        "Other non-specialised retail sale": {
            SCAT: { code: "86", description: "Departmental and Walk Round Stores (Large)" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.12", description: "Other non-specialised retail sale" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Retail sale of fruit and vegetables": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.21", description: "Retail sale services of fruit and vegetables" },
            ISIC: { code: "4721", description: "Retail sale of food" },
            NACE: { code: "47.21", description: "Retail sale of fruit and vegetables" },
            UK_SIC: { code: "47.21", description: "Retail sale of fruit and vegetables in specialised stores" },
        },
        "Retail sale of meat and meat products": {
            SCAT: { code: "158", description: "Livestock Markets (National Scheme)" },
            CPA: { code: "47.22", description: "Retail sale services of meat and meat products" },
            ISIC: { code: "4722", description: "Retail sale of beverages" },
            NACE: { code: "47.22", description: "Retail sale of meat and meat products" },
            UK_SIC: { code: "47.22", description: "Retail sale of meat and meat products in specialised stores" },
        },
        "Retail sale of fish, crustaceans and molluscs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.23", description: "Retail sale services of fish, crustaceans and molluscs" },
            ISIC: { code: "4723", description: "Retail sale of tobacco products" },
            NACE: { code: "47.23", description: "Retail sale of fish, crustaceans and molluscs" },
            UK_SIC: { code: "47.23", description: "Retail sale of fish, crustaceans and molluscs in specialised stores" },
        },
        "Retail sale of bread, cake and confectionery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.24", description: "Retail sale services of bread, cake and confectionery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.24", description: "Retail sale of bread, cake and confectionery" },
            UK_SIC: { code: "47.24", description: "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores" },
        },
        "Retail sale of beverages": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.25", description: "Retail sale services of beverages" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.25", description: "Retail sale of beverages" },
            UK_SIC: { code: "47.25", description: "Retail sale of beverages in specialised stores" },
        },
        "Retail sale of tobacco products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.26", description: "Retail sale services of tobacco products" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.26", description: "Retail sale of tobacco products" },
            UK_SIC: { code: "47.26", description: "Retail sale of tobacco products in specialised stores" },
        },
        "Retail sale of other food": {
            SCAT: { code: "120\n152", description: "Granaries and Intervention Stores\nLarge Food Stores (750 - 2500m²)" },
            CPA: { code: "47.27", description: "Retail sale services of other food" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.27", description: "Retail sale of other food" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Retail sale of automotive fuel": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.30", description: "Retail sale services of automotive fuel" },
            ISIC: { code: "4730", description: "Retail sale of automotive fuel" },
            NACE: { code: "47.30", description: "Retail sale of automotive fuel" },
            UK_SIC: { code: "47.30", description: "Retail sale of automotive fuel in specialised stores" },
        },
        "Retail sale of information and communication equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.40", description: "Retail sale services of information and communication equipment" },
            ISIC: { code: "4740", description: "Retail sale of information and communication equipment" },
            NACE: { code: "47.40", description: "Retail sale of information and communication equipment" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Retail sale of textiles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.51", description: "Retail sale services of textiles" },
            ISIC: { code: "4751", description: "Retail sale of textiles" },
            NACE: { code: "47.51", description: "Retail sale of textiles" },
            UK_SIC: { code: "47.51", description: "Retail sale of textiles in specialised stores" },
        },
        "Retail sale of hardware, building materials, paints and glass": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.52", description: "Retail sale services of hardware, building materials, paints and glass" },
            ISIC: { code: "4752", description: "Retail sale of hardware, building materials, paints and glass" },
            NACE: { code: "47.52", description: "Retail sale of hardware, building materials, paints and glass" },
            UK_SIC: { code: "47.52", description: "Retail sale of hardware, paints and glass in specialised stores" },
        },
        "Retail sale of carpets, rugs, wall and floor coverings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.53", description: "Retail sale services of carpets, rugs, wall and floor coverings" },
            ISIC: { code: "4753", description: "Retail sale of carpets, rugs, wall and floor coverings" },
            NACE: { code: "47.53", description: "Retail sale of carpets, rugs, wall and floor coverings" },
            UK_SIC: { code: "47.53", description: "Retail sale of carpets, rugs, wall and floor coverings in specialised stores" },
        },
        "Retail sale of electrical household appliances": {
            SCAT: { code: "733", description: "Battery Store" },
            CPA: { code: "47.54", description: "Retail sale services of electrical household appliances" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.54", description: "Retail sale of electrical household appliances" },
            UK_SIC: { code: "47.54", description: "Retail sale of electrical household appliances in specialised stores" },
        },
        "Retail sale of furniture, lighting equipment, tableware and other household goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.55", description: "Retail sale services of furniture, lighting equipment, tableware and other household goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.55", description: "Retail sale of furniture, lighting equipment, tableware and other household goods" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Retail sale of books": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.61", description: "Retail sale services of books" },
            ISIC: { code: "4761", description: "Retail sale of books, newspapers, stationery and office supplies" },
            NACE: { code: "47.61", description: "Retail sale of books" },
            UK_SIC: { code: "47.61", description: "Retail sale of books in specialised stores" },
        },
        "Retail sale of newspapers, and other periodical publications and stationery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.62", description: "Retail sale services of newspapers, and other periodical publications and stationery" },
            ISIC: { code: "4762", description: "Retail sale of sporting equipment" },
            NACE: { code: "47.62", description: "Retail sale of newspapers, and other periodical publications and stationery" },
            UK_SIC: { code: "47.62", description: "Retail sale of newspapers and stationery in specialised stores" },
        },
        "Retail sale of sporting equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.63", description: "Retail sale services of sporting and camping equipment" },
            ISIC: { code: "4763", description: "Retail sale of games and toys" },
            NACE: { code: "47.63", description: "Retail sale of sporting equipment" },
            UK_SIC: { code: "47.63", description: "Retail sale of music and video recordings in specialised stores" },
        },
        "Retail sale of games and toys": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.64", description: "Retail sale services of games and toys" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.64", description: "Retail sale of games and toys" },
            UK_SIC: { code: "47.64", description: "Retail sale of sporting equipment in specialised stores" },
        },
        "Retail sale of cultural and recreational goods not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.69", description: "Retail sale services of cultural and recreational goods n.e.c." },
            ISIC: { code: "4769", description: "Retail sale of cultural and recreational goods not elsewhere classified" },
            NACE: { code: "47.69", description: "Retail sale of cultural and recreational goods not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Retail sale of clothing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.71", description: "Retail sale services of clothing" },
            ISIC: { code: "4771", description: "Retail sale of clothing, footwear and leather articles" },
            NACE: { code: "47.71", description: "Retail sale of clothing" },
            UK_SIC: { code: "47.71", description: "Retail sale of clothing in specialised stores" },
        },
        "Retail sale of footwear and leather goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.72", description: "Retail sale services of footwear and leather goods" },
            ISIC: { code: "4772", description: "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles" },
            NACE: { code: "47.72", description: "Retail sale of footwear and leather goods" },
            UK_SIC: { code: "47.72", description: "Retail sale of footwear and leather goods in specialised stores" },
        },
        "Retail sale of pharmaceutical products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.73", description: "Retail sale services of pharmaceutical products" },
            ISIC: { code: "4773", description: "Retail sale of other new goods not elsewhere classified" },
            NACE: { code: "47.73", description: "Retail sale of pharmaceutical products" },
            UK_SIC: { code: "47.73", description: "Dispensing chemist in specialised stores" },
        },
        "Retail sale of medical and orthopaedic goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.74", description: "Retail sale services of medical and orthopaedic goods" },
            ISIC: { code: "4774", description: "Retail sale of second-hand goods" },
            NACE: { code: "47.74", description: "Retail sale of medical and orthopaedic goods" },
            UK_SIC: { code: "47.74", description: "Retail sale of medical and orthopaedic goods in specialised stores" },
        },
        "Retail sale of cosmetic and toilet articles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.75", description: "Retail sale services of cosmetic and toilet articles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.75", description: "Retail sale of cosmetic and toilet articles" },
            UK_SIC: { code: "47.75", description: "Retail sale of cosmetic and toilet articles in specialised stores" },
        },
        "Retail sale of flowers, plants, fertilisers, pets and pet food": {
            SCAT: { code: "114", description: "Garden Centres" },
            CPA: { code: "47.76", description: "Retail sale services of flowers, plants, fertilisers, pets and pet food" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.76", description: "Retail sale of flowers, plants, fertilisers, pets and pet food" },
            UK_SIC: { code: "47.76", description: "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores" },
        },
        "Retail sale of watches and jewellery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.77", description: "Retail sale services of watches and jewellery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.77", description: "Retail sale of watches and jewellery" },
            UK_SIC: { code: "47.77", description: "Retail sale of watches and jewellery in specialised stores" },
        },
        "Retail sale of other new goods": {
            SCAT: { code: "251\n97\n98\n154\n155\n249\n508", description: "Showrooms\nFactory Shops\nFarm Shops\nLarge Shops (750 - 1850m²)\nLarge Shops (Over 1850m²)\nShops\nShops Within/Part of Specialist Property" },
            CPA: { code: "47.78", description: "Retail sale services of other new goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.78", description: "Retail sale of other new goods" },
            UK_SIC: { code: "47.78", description: "Other retail sale of new goods in specialised stores" },
        },
        "Retail sale of second-hand goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.79", description: "Retail sale services of second-hand goods, except motor vehicles and motorcycles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.79", description: "Retail sale of second-hand goods" },
            UK_SIC: { code: "47.79", description: "Retail sale of second-hand goods in stores" },
        },
        "Retail sale of motor vehicles": {
            SCAT: { code: "46\n42\n43\n44", description: "Car/Caravan Sales/Display/Hiring Sites\nCar Showrooms\nCar Spaces\nCar Supermarkets" },
            CPA: { code: "47.81", description: "Retail sale services of motor vehicles" },
            ISIC: { code: "4781", description: "Retail sale of motor vehicles" },
            NACE: { code: "47.81", description: "Retail sale of motor vehicles" },
            UK_SIC: { code: "47.81", description: "Retail sale via stalls and markets of food, beverages and tobacco products" },
        },
        "Retail sale of motor vehicle parts and accessories": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.82", description: "Retail sale services of motor vehicle parts and accessories" },
            ISIC: { code: "4782", description: "Retail sale of motor vehicle parts and accessories" },
            NACE: { code: "47.82", description: "Retail sale of motor vehicle parts and accessories" },
            UK_SIC: { code: "47.82", description: "Retail sale via stalls and markets of textiles, clothing and footwear" },
        },
        "Retail sale of motorcycles, motorcycle parts and accessories": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "47.83", description: "Retail sale services of motorcycles, motorcycle parts and accessories" },
            ISIC: { code: "4783", description: "Retail sale of motorcycles, motorcycles parts and accessories" },
            NACE: { code: "47.83", description: "Retail sale of motorcycles, motorcycle parts and accessories" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for non-specialised retail sale": {
            SCAT: { code: "710\n705\n165", description: "Residual Mall\nMarket Stall (Not Pitch)\nMarkets (Other Than Livestock)" },
            CPA: { code: "", description: "missing entry in classification" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.91", description: "Intermediation service activities for non-specialised retail sale" },
            UK_SIC: { code: "47.91", description: "Retail sale via mail order houses or via Internet" },
        },
        "Intermediation service activities for specialised retail sale": {
            SCAT: { code: "709\n147", description: "Dark Retailing/Internet Sites\nLand Used For Car Boot Sales" },
            CPA: { code: "47.92", description: "Intermediation services of retail sale services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "47.92", description: "Intermediation service activities for specialised retail sale" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Passenger heavy rail transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.11", description: "Passenger heavy rail transport services" },
            ISIC: { code: "4911", description: "Passenger rail transport, interurban" },
            NACE: { code: "49.11", description: "Passenger heavy rail transport" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other passenger rail transport": {
            SCAT: { code: "128", description: "Heritage Railways" },
            CPA: { code: "49.12", description: "Other passenger rail transport services" },
            ISIC: { code: "4912", description: "Freight rail transport" },
            NACE: { code: "49.12", description: "Other passenger rail transport" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Freight rail transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.20", description: "Freight rail transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.20", description: "Freight rail transport" },
            UK_SIC: { code: "49.20", description: "Freight rail transport" },
        },
        "Scheduled passenger transport by road": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.31", description: "Scheduled passenger transport services by road" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.31", description: "Scheduled passenger transport by road" },
            UK_SIC: { code: "49.31", description: "Urban and suburban passenger land transport" },
        },
        "Non-scheduled passenger transport by road": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.32", description: "Non-scheduled passenger transport services by road" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.32", description: "Non-scheduled passenger transport by road" },
            UK_SIC: { code: "49.32", description: "Taxi operation" },
        },
        "On-demand passenger transport service activities by vehicle with driver": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.33", description: "On-demand passenger transport services by vehicle with driver" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.33", description: "On-demand passenger transport service activities by vehicle with driver" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Passenger transport by cableways and ski lifts": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.34", description: "Passenger transport services by cableways and ski lifts" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.34", description: "Passenger transport by cableways and ski lifts" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other passenger land transport not elsewhere classified": {
            SCAT: { code: "36\n713", description: "Bus Stations\nBicycle Folding Box Site" },
            CPA: { code: "49.39", description: "Other passenger land transport services n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.39", description: "Other passenger land transport not elsewhere classified" },
            UK_SIC: { code: "49.39", description: "Other passenger land transport n.e.c." },
        },
        "Freight transport by road": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.41", description: "Freight transport services by road" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.41", description: "Freight transport by road" },
            UK_SIC: { code: "49.41", description: "Freight transport by road" },
        },
        "Removal services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.42", description: "Removal services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.42", description: "Removal services" },
            UK_SIC: { code: "49.42", description: "Removal services" },
        },
        "Transport via pipeline": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "49.50", description: "Transport services via pipeline" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "49.50", description: "Transport via pipeline" },
            UK_SIC: { code: "49.50", description: "Transport via pipeline" },
        },
        "Sea and coastal passenger water transport": {
            SCAT: { code: "89\n433\n434\n435", description: "Docks and Harbours (Non-Statutory)\nStatutory Docks and Harbours (Formula)\nStatutory Docks and Harbours (Non- Formula, Prescribed)\nStatutory Docks and Harbours (Other)" },
            CPA: { code: "50.10", description: "Sea and coastal passenger water transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "50.10", description: "Sea and coastal passenger water transport" },
            UK_SIC: { code: "50.10", description: "Sea and coastal passenger water transport" },
        },
        "Sea and coastal freight water transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "50.20", description: "Sea and coastal freight water transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "50.20", description: "Sea and coastal freight water transport" },
            UK_SIC: { code: "50.20", description: "Sea and coastal freight water transport" },
        },
        "Inland passenger water transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "50.30", description: "Inland passenger water transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "50.30", description: "Inland passenger water transport" },
            UK_SIC: { code: "50.30", description: "Inland passenger water transport" },
        },
        "Inland freight water transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "50.40", description: "Inland freight water transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "50.40", description: "Inland freight water transport" },
            UK_SIC: { code: "50.40", description: "Inland freight water transport" },
        },
        "Passenger air transport": {
            SCAT: { code: "5\n7\n8\n59", description: "Air Ports (Minor) (National Scheme)\nAircraft Works With Airfields\nAirport Let Outs\nCivil Airports" },
            CPA: { code: "51.10", description: "Passenger air transport services" },
            ISIC: { code: "5110", description: "Passenger air transport" },
            NACE: { code: "51.10", description: "Passenger air transport" },
            UK_SIC: { code: "51.10", description: "Passenger air transport" },
        },
        "Freight air transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "51.21", description: "Freight air transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "51.21", description: "Freight air transport" },
            UK_SIC: { code: "51.21", description: "Freight air transport" },
        },
        "Space transport": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "51.22", description: "Space transport services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "51.22", description: "Space transport" },
            UK_SIC: { code: "51.22", description: "Space transport" },
        },
        "Warehousing and storage": {
            SCAT: { code: "27\n35\n113\n406\n407\n502\n39\n40\n41\n501\n33\n161\n725\n301\n511\n721\n712\n717\n64\n119\n120\n129\n148\n151\n157", description: "Boat Yards\nBus Garages\nGarages (Transport and Commercial)\nBus Garages (Contractors Valuation)\nBus Garages (Rental Valuation)\nGarages Within/Part of Specialist Property\nCar Parks (Multi-Storey)\nCar Parks (Surfaced Open)\nCar Parks (Unsurfaced Open)\nCar Parking Within/Part of Specialist Property\nBulk Cement Storage Depots\nLorry Parks\nPark & Ride Car Parks\nWholesale Warehouses\nWarehouses Within/Part of Specialist Property\nSelf Storage Facility\nRail Freight Depots\nDelivery Box Site & Premises\nCold Stores\nGrain Silos\nGranaries and Intervention Stores\nHigh Tech Warehouses\nLand Used For Storage\nLarge Distribution Warehouses\nLiquid Bulk Storage (Incl Petrol & Oil) (National Scheme)" },
            CPA: { code: "52.10", description: "Warehousing and storage services" },
            ISIC: { code: "5210", description: "Warehousing and storage" },
            NACE: { code: "52.10", description: "Warehousing and storage" },
            UK_SIC: { code: "52.10", description: "Warehousing and storage" },
        },
        "Service activities incidental to land transportation": {
            SCAT: { code: "724", description: "Street Points (eg Cycle Docking Stations)" },
            CPA: { code: "52.21", description: "Services incidental to land transportation" },
            ISIC: { code: "5221", description: "Service activities incidental to land transportation" },
            NACE: { code: "52.21", description: "Service activities incidental to land transportation" },
            UK_SIC: { code: "52.21", description: "Service activities incidental to land transportation" },
        },
        "Service activities incidental to water transportation": {
            SCAT: { code: "420\n737", description: "Lifeboat Stations\nFerry Terminal" },
            CPA: { code: "52.22", description: "Services incidental to water transportation" },
            ISIC: { code: "5222", description: "Service activities incidental to water transportation" },
            NACE: { code: "52.22", description: "Service activities incidental to water transportation" },
            UK_SIC: { code: "52.22", description: "Service activities incidental to water transportation" },
        },
        "Service activities incidental to air transportation": {
            SCAT: { code: "6\n126", description: "Air Strips (National Scheme)\nHeliports" },
            CPA: { code: "52.23", description: "Services incidental to air transportation" },
            ISIC: { code: "5223", description: "Service activities incidental to air transportation" },
            NACE: { code: "52.23", description: "Service activities incidental to air transportation" },
            UK_SIC: { code: "52.23", description: "Service activities incidental to air transportation" },
        },
        "Cargo handling": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "52.24", description: "Cargo handling services" },
            ISIC: { code: "5224", description: "Cargo handling" },
            NACE: { code: "52.24", description: "Cargo handling" },
            UK_SIC: { code: "52.24", description: "Cargo handling" },
        },
        "Logistics service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "52.25", description: "Logistics services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "52.25", description: "Logistics service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other support activities for transportation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "52.26", description: "Other transportation support services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "52.26", description: "Other support activities for transportation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for freight transportation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "52.31", description: "Intermediation services of freight transportation" },
            ISIC: { code: "5231", description: "Intermediation service activities for freight transportation" },
            NACE: { code: "52.31", description: "Intermediation service activities for freight transportation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for passenger transportation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "52.32", description: "Intermediation services of passenger transportation" },
            ISIC: { code: "5232", description: "Intermediation service activities for passenger transportation" },
            NACE: { code: "52.32", description: "Intermediation service activities for passenger transportation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Postal activities under universal service obligation": {
            SCAT: { code: "217\n429\n732", description: "Post Office Sorting Centres\nPost Offices\nClick & Collect Locker Site" },
            CPA: { code: "53.10", description: "Postal services under universal service obligation" },
            ISIC: { code: "5310", description: "Postal activities" },
            NACE: { code: "53.10", description: "Postal activities under universal service obligation" },
            UK_SIC: { code: "53.10", description: "Postal activities under universal service obligation" },
        },
        "Other postal and courier activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "53.20", description: "Other postal and courier services" },
            ISIC: { code: "5320", description: "Courier activities" },
            NACE: { code: "53.20", description: "Other postal and courier activities" },
            UK_SIC: { code: "53.20", description: "Other postal and courier activities" },
        },
        "Intermediation service activities for postal and courier activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "53.30", description: "Intermediation services of postal and courier services" },
            ISIC: { code: "5330", description: "Intermediation service activities for postal and courier activities" },
            NACE: { code: "53.30", description: "Intermediation service activities for postal and courier activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Hotels and similar accommodation": {
            SCAT: { code: "62\n130\n131\n132\n136\n137\n138\n77\n122\n736", description: "Inns\nHoliday Centres\nHoliday Homes (Self Catering)\nHorse Racecourses\nHostels\nHotels (3 Star And Under)\nHotels (4 Star & Above) & Major Chain Operated ( includes 3* & above consortium hotels)\nCountry House Hotels\nGuest & Boarding Houses\nHoliday Home Self Catering (Complexs)" },
            CPA: { code: "55.10", description: "Hotel and similar accommodation services" },
            ISIC: { code: "5510", description: "Hotels and similar accommodation activities" },
            NACE: { code: "55.10", description: "Hotels and similar accommodation" },
            UK_SIC: { code: "55.10", description: "Hotels and similar accommodation" },
        },
        "Holiday and other short-stay accommodation": {
            SCAT: { code: "722\n22\n160", description: "Serviced Appartments\nBeach Huts\nLodges (National Scheme)" },
            CPA: { code: "55.20", description: "Holiday and other short-stay accommodation services" },
            ISIC: { code: "5520", description: "Other short term accommodation activities" },
            NACE: { code: "55.20", description: "Holiday and other short-stay accommodation" },
            UK_SIC: { code: "55.20", description: "Holiday and other short-stay accommodation" },
        },
        "Camping grounds and recreational vehicle parks": {
            SCAT: { code: "47\n48\n54", description: "Caravan Parks (Leisure) (National Scheme)\nCaravan Sites and Pitches (National Scheme)\nChalet Parks (National Scheme)" },
            CPA: { code: "55.30", description: "Camping grounds and recreational vehicle parks services" },
            ISIC: { code: "5530", description: "Camping grounds, recreational vehicle parks and trailer parks" },
            NACE: { code: "55.30", description: "Camping grounds and recreational vehicle parks" },
            UK_SIC: { code: "55.30", description: "Camping grounds, recreational vehicle parks and trailer parks" },
        },
        "Intermediation service activities for accommodation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "55.40", description: "Intermediation services of accommodation" },
            ISIC: { code: "5540", description: "Intermediation service activities for accommodation" },
            NACE: { code: "55.40", description: "Intermediation service activities for accommodation" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other accommodation": {
            SCAT: { code: "123", description: "Traveller Sites" },
            CPA: { code: "55.90", description: "Other accommodation services" },
            ISIC: { code: "5590", description: "Other accommodation not elsewhere classified" },
            NACE: { code: "55.90", description: "Other accommodation" },
            UK_SIC: { code: "55.90", description: "Other accommodation" },
        },
        "Restaurant activities": {
            SCAT: { code: "500\n716\n91\n92\n234\n238\n701", description: "Cafes/Restaurants Within/Part of Specialist Property\nCafe/Restaurants   Within/Part   of NonSpecialist Properties\nDrive-In Restaurants\nDrive-Thru Restaurants\nRestaurants\nRoadside Restaurants (National Scheme)\nRestaurant With Letting Accomodation" },
            CPA: { code: "56.11", description: "Restaurant serving services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "56.11", description: "Restaurant activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Mobile food service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "56.12", description: "Mobile food serving services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "56.12", description: "Mobile food service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Event catering activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "56.21", description: "Event catering services" },
            ISIC: { code: "5621", description: "Event catering activities" },
            NACE: { code: "56.21", description: "Event catering activities" },
            UK_SIC: { code: "56.21", description: "Event catering activities" },
        },
        "Contract catering service activities and other food service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "56.22", description: "Contract catering services and other food services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "56.22", description: "Contract catering service activities and other food service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Beverage serving activities": {
            SCAT: { code: "409\n500\n716\n60\n225\n226\n227", description: "Cafes\nCafes/Restaurants Within/Part of Specialist Property\nCafe/Restaurants   Within/Part   of NonSpecialist Properties\nClubhouses\nPublic Halls\nPublic    Houses/Pub    Restaurants (National Scheme)\nPublic Houses/Pub Restaurants (Inc. Lodge) (National Scheme)" },
            CPA: { code: "56.30", description: "Beverage serving services" },
            ISIC: { code: "5630", description: "Beverage serving activities" },
            NACE: { code: "56.30", description: "Beverage serving activities" },
            UK_SIC: { code: "56.30", description: "Beverage serving activities" },
        },
        "Intermediation service activities for food and beverage services activities": {
            SCAT: { code: "104\n442", description: "Food Courts\nTakeaway Food Outlet (Predominantly Off Premises)" },
            CPA: { code: "56.40", description: "Intermediation services of food and beverage serving services" },
            ISIC: { code: "5640", description: "Intermediation service activities for food and beverage services activities" },
            NACE: { code: "56.40", description: "Intermediation service activities for food and beverage services activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Publishing of books": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.11", description: "Publishing services of books" },
            ISIC: { code: "5811", description: "Publishing of books" },
            NACE: { code: "58.11", description: "Publishing of books" },
            UK_SIC: { code: "58.11", description: "Book publishing" },
        },
        "Publishing of newspapers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.12", description: "Publishing services of newspapers" },
            ISIC: { code: "5812", description: "Publishing of newspapers" },
            NACE: { code: "58.12", description: "Publishing of newspapers" },
            UK_SIC: { code: "58.12", description: "Publishing of directories and mailing lists" },
        },
        "Publishing of journals and periodicals": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.13", description: "Publishing services of journals and periodicals" },
            ISIC: { code: "5813", description: "Publishing of journals and periodicals" },
            NACE: { code: "58.13", description: "Publishing of journals and periodicals" },
            UK_SIC: { code: "58.13", description: "Publishing of newspapers" },
        },
        "Other publishing activities, except software publishing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.19", description: "Other publishing services, except software publishing" },
            ISIC: { code: "5819", description: "Other publishing activities" },
            NACE: { code: "58.19", description: "Other publishing activities, except software publishing" },
            UK_SIC: { code: "58.19", description: "Other publishing activities" },
        },
        "Publishing of video games": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.21", description: "Publishing services of video games" },
            ISIC: { code: "5821", description: "Publishing of video games" },
            NACE: { code: "58.21", description: "Publishing of video games" },
            UK_SIC: { code: "58.21", description: "Publishing of computer games" },
        },
        "Other software publishing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "58.29", description: "Other software publishing services" },
            ISIC: { code: "5829", description: "Other software publishing" },
            NACE: { code: "58.29", description: "Other software publishing" },
            UK_SIC: { code: "58.29", description: "Other software publishing" },
        },
        "Motion picture, video and television programme production activities": {
            SCAT: { code: "100", description: "Film and TV Studios" },
            CPA: { code: "59.11", description: "Motion picture, video and television programme production services" },
            ISIC: { code: "5911", description: "Motion picture, video and television programme production activities" },
            NACE: { code: "59.11", description: "Motion picture, video and television programme production activities" },
            UK_SIC: { code: "59.11", description: "Motion picture, video and television programme production activities" },
        },
        "Motion picture, video and television programme post-production activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "59.12", description: "Motion picture, video and television programme post-production services" },
            ISIC: { code: "5912", description: "Motion picture, video and television programme post-production activities" },
            NACE: { code: "59.12", description: "Motion picture, video and television programme post-production activities" },
            UK_SIC: { code: "59.12", description: "Motion picture, video and television programme post-production activities" },
        },
        "Motion picture and video distribution activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "59.13", description: "Motion picture and video distribution services" },
            ISIC: { code: "5913", description: "Motion picture, video and television programme distribution activities" },
            NACE: { code: "59.13", description: "Motion picture and video distribution activities" },
            UK_SIC: { code: "59.13", description: "Motion picture, video and television programme distribution activities" },
        },
        "Motion picture projection activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "59.14", description: "Motion picture projection services" },
            ISIC: { code: "5914", description: "Motion picture projection activities" },
            NACE: { code: "59.14", description: "Motion picture projection activities" },
            UK_SIC: { code: "59.14", description: "Motion picture projection activities" },
        },
        "Sound recording and music publishing activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "59.20", description: "Sound recording and music publishing services" },
            ISIC: { code: "5920", description: "Sound recording and music publishing activities" },
            NACE: { code: "59.20", description: "Sound recording and music publishing activities" },
            UK_SIC: { code: "59.20", description: "Sound recording and music publishing activities" },
        },
        "Radio broadcasting and audio distribution activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "60.10", description: "Radio broadcasting and audio distribution services" },
            ISIC: { code: "6010", description: "Radio broadcasting and audio distribution activities" },
            NACE: { code: "60.10", description: "Radio broadcasting and audio distribution activities" },
            UK_SIC: { code: "60.10", description: "Radio broadcasting" },
        },
        "Television programming, broadcasting and video distribution activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "60.20", description: "Television programming, broadcasting and video distribution services" },
            ISIC: { code: "6020", description: "Television programming, broadcasting and video distribution activities" },
            NACE: { code: "60.20", description: "Television programming, broadcasting and video distribution activities" },
            UK_SIC: { code: "60.20", description: "Television programming and broadcasting activities" },
        },
        "News agency activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "60.31", description: "News agency services" },
            ISIC: { code: "6031", description: "News agency activities" },
            NACE: { code: "60.31", description: "News agency activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other content distribution activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "60.39", description: "Other content distribution services" },
            ISIC: { code: "6039", description: "Social network sites and other content distribution activities" },
            NACE: { code: "60.39", description: "Other content distribution activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Wired, wireless, and satellite telecommunication activities": {
            SCAT: { code: "438\n37", description: "Telescope Sites\nCable Head End Buildings" },
            CPA: { code: "61.10", description: "Wired, wireless, and satellite telecommunication services" },
            ISIC: { code: "6110", description: "Wired, wireless, and satellite telecommunication activities" },
            NACE: { code: "61.10", description: "Wired, wireless, and satellite telecommunication activities" },
            UK_SIC: { code: "61.10", description: "Wired telecommunications activities" },
        },
        "Telecommunication reselling activities and intermediation service activities for telecommunication": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "61.20", description: "Telecommunication reselling services and intermediation services of telecommunication services" },
            ISIC: { code: "6120", description: "Telecommunication reselling activities and intermediation service activities for telecommunication" },
            NACE: { code: "61.20", description: "Telecommunication reselling activities and intermediation service activities for telecommunication" },
            UK_SIC: { code: "61.20", description: "Wireless telecommunications activities" },
        },
        "Other telecommunication activities": {
            SCAT: { code: "66\n276\n726\n141", description: "Communication Stations (National Scheme)\nTelecommunications Switching Centres\nTelecommunications Large Broadcast Sites\nInteractive Telephone Kiosks" },
            CPA: { code: "61.90", description: "Other telecommunication services" },
            ISIC: { code: "6190", description: "Other telecommunication activities" },
            NACE: { code: "61.90", description: "Other telecommunication activities" },
            UK_SIC: { code: "61.90", description: "Other telecommunications activities" },
        },
        "Computer programming activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "62.10", description: "Computer programming services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "62.10", description: "Computer programming activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Computer consultancy and computer facilities management activities": {
            SCAT: { code: "68\n69", description: "Computer Centres (Non-Purpose Built)\nComputer Centres (Purpose Built)" },
            CPA: { code: "62.20", description: "Computer consultancy and computer facilities management services" },
            ISIC: { code: "6220", description: "Computer consultancy and computer facilities management activities" },
            NACE: { code: "62.20", description: "Computer consultancy and computer facilities management activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other information technology and computer service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "62.90", description: "Other information technology and computer services" },
            ISIC: { code: "6290", description: "Other information technology and computer service activities" },
            NACE: { code: "62.90", description: "Other information technology and computer service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Computing infrastructure, data processing, hosting and related activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "63.10", description: "Computing infrastructure, data processing, hosting and related services" },
            ISIC: { code: "6310", description: "Computing infrastructure, data processing, hosting and related activities" },
            NACE: { code: "63.10", description: "Computing infrastructure, data processing, hosting and related activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Web search portal activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "63.91", description: "Web search portal services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "63.91", description: "Web search portal activities" },
            UK_SIC: { code: "63.91", description: "News agency activities" },
        },
        "Other information service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "63.92", description: "Other information services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "63.92", description: "Other information service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Central banking": {
            SCAT: { code: "21", description: "Banks/Insurance/Building Society Offices & Other A2 Uses" },
            CPA: { code: "64.11", description: "Central banking services" },
            ISIC: { code: "6411", description: "Central banking" },
            NACE: { code: "64.11", description: "Central banking" },
            UK_SIC: { code: "64.11", description: "Central banking" },
        },
        "Other monetary intermediation": {
            SCAT: { code: "18\n19\n38\n34", description: "ATMs\nAuction Rooms\nCar Auction Buildings/Sites\nBullion/Money Stores (National Scheme)" },
            CPA: { code: "64.19", description: "Other monetary intermediation services" },
            ISIC: { code: "6419", description: "Other monetary intermediation" },
            NACE: { code: "64.19", description: "Other monetary intermediation" },
            UK_SIC: { code: "64.19", description: "Other monetary intermediation" },
        },
        "Activities of holding companies": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.21", description: "Services of holding companies" },
            ISIC: { code: "6421", description: "Activities of holding companies" },
            NACE: { code: "64.21", description: "Activities of holding companies" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of financing conduits": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.22", description: "Services of financing conduits" },
            ISIC: { code: "6422", description: "Activities of financing conduits" },
            NACE: { code: "64.22", description: "Activities of financing conduits" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of money market and non-money market investments funds": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.31", description: "Services of money market and non-money market investments funds" },
            ISIC: { code: "6431", description: "Activities of money market funds" },
            NACE: { code: "64.31", description: "Activities of money market and non-money market investments funds" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of trust, estate and agency accounts": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.32", description: "Services of trust, estate and agency accounts" },
            ISIC: { code: "6432", description: "Activities of non-money market investments funds" },
            NACE: { code: "64.32", description: "Activities of trust, estate and agency accounts" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Financial leasing": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.91", description: "Financial leasing services" },
            ISIC: { code: "6491", description: "Financial leasing activities" },
            NACE: { code: "64.91", description: "Financial leasing" },
            UK_SIC: { code: "64.91", description: "Financial leasing" },
        },
        "Other credit granting": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "64.92", description: "Other credit granting services" },
            ISIC: { code: "6492", description: "International trade financing activities" },
            NACE: { code: "64.92", description: "Other credit granting" },
            UK_SIC: { code: "64.92", description: "Other credit granting" },
        },
        "Other financial service activities, except insurance and pension funding not elsewhere classified": {
            SCAT: { code: "21", description: "Banks/Insurance/Building Society Offices & Other A2 Uses" },
            CPA: { code: "64.99", description: "Other financial services, except insurance and pension funding n.e.c." },
            ISIC: { code: "6499", description: "Other financial service activities not elsewhere classified, except insurance and pension funding activities" },
            NACE: { code: "64.99", description: "Other financial service activities, except insurance and pension funding not elsewhere classified" },
            UK_SIC: { code: "64.99", description: "Other financial service activities, except insurance and pension funding, n.e.c." },
        },
        "Life insurance": {
            SCAT: { code: "21", description: "Banks/Insurance/Building Society Offices & Other A2 Uses" },
            CPA: { code: "65.11", description: "Life insurance services" },
            ISIC: { code: "6511", description: "Life insurance" },
            NACE: { code: "65.11", description: "Life insurance" },
            UK_SIC: { code: "65.11", description: "Life insurance" },
        },
        "Non-life insurance": {
            SCAT: { code: "21", description: "Banks/Insurance/Building Society Offices & Other A2 Uses" },
            CPA: { code: "65.12", description: "Non-life insurance services" },
            ISIC: { code: "6512", description: "Non-life insurance" },
            NACE: { code: "65.12", description: "Non-life insurance" },
            UK_SIC: { code: "65.12", description: "Non-life insurance" },
        },
        "Reinsurance": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "65.20", description: "Reinsurance services" },
            ISIC: { code: "6520", description: "Reinsurance" },
            NACE: { code: "65.20", description: "Reinsurance" },
            UK_SIC: { code: "65.20", description: "Reinsurance" },
        },
        "Pension funding": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "65.30", description: "Pension funding services" },
            ISIC: { code: "6530", description: "Pension funding" },
            NACE: { code: "65.30", description: "Pension funding" },
            UK_SIC: { code: "65.30", description: "Pension funding" },
        },
        "Administration of financial markets": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.11", description: "Services related to the administration of financial markets" },
            ISIC: { code: "6611", description: "Administration of financial markets" },
            NACE: { code: "66.11", description: "Administration of financial markets" },
            UK_SIC: { code: "66.11", description: "Administration of financial markets" },
        },
        "Security and commodity contracts brokerage": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.12", description: "Security and commodity contracts brokerage services" },
            ISIC: { code: "6612", description: "Security and commodity contracts brokerage" },
            NACE: { code: "66.12", description: "Security and commodity contracts brokerage" },
            UK_SIC: { code: "66.12", description: "Security and commodity contracts brokerage" },
        },
        "Other activities auxiliary to financial services, except insurance and pension funding": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.19", description: "Other services auxiliary to financial services, except insurance and pension funding" },
            ISIC: { code: "6619", description: "Other activities auxiliary to financial service activities, except insurance and pension funding " },
            NACE: { code: "66.19", description: "Other activities auxiliary to financial services, except insurance and pension funding" },
            UK_SIC: { code: "66.19", description: "Other activities auxiliary to financial services, except insurance and pension funding" },
        },
        "Risk and damage evaluation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.21", description: "Risk and damage evaluation services" },
            ISIC: { code: "6621", description: "Risk and damage evaluation" },
            NACE: { code: "66.21", description: "Risk and damage evaluation" },
            UK_SIC: { code: "66.21", description: "Risk and damage evaluation" },
        },
        "Activities of insurance agents and brokers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.22", description: "Services of insurance agents and brokers" },
            ISIC: { code: "6622", description: "Activities of insurance agents and brokers" },
            NACE: { code: "66.22", description: "Activities of insurance agents and brokers" },
            UK_SIC: { code: "66.22", description: "Activities of insurance agents and brokers" },
        },
        "Activities auxiliary to insurance and pension funding not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.29", description: "Other services auxiliary to insurance and pension funding" },
            ISIC: { code: "6629", description: "Other activities auxiliary to insurance and pension funding" },
            NACE: { code: "66.29", description: "Activities auxiliary to insurance and pension funding not elsewhere classified" },
            UK_SIC: { code: "66.29", description: "Other activities auxiliary to insurance and pension funding" },
        },
        "Fund management activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "66.30", description: "Fund management services" },
            ISIC: { code: "6630", description: "Fund management activities" },
            NACE: { code: "66.30", description: "Fund management activities" },
            UK_SIC: { code: "66.30", description: "Fund management activities" },
        },
        "Buying and selling of own real estate": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "68.11", description: "Buying and selling services of own real estate" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "68.11", description: "Buying and selling of own real estate" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Development of building projects": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "68.12", description: "Development services of building projects; development services of civil engineering projects" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "68.12", description: "Development of building projects" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Rental and operating of own or leased real estate": {
            SCAT: { code: "133\n8\n193\n266", description: "Hospital Let Outs\nAirport Let Outs\nMotorway Service Area Let Outs\nStation Let Outs" },
            CPA: { code: "68.20", description: "Rental and operating services of own or leased real estate" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "68.20", description: "Rental and operating of own or leased real estate" },
            UK_SIC: { code: "68.20", description: "Renting and operating of own or leased real estate" },
        },
        "Intermediation service activities for real estate activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "68.31", description: "Intermediation services of real estate services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "68.31", description: "Intermediation service activities for real estate activities" },
            UK_SIC: { code: "68.31", description: "Real estate agencies" },
        },
        "Other real estate activities on a fee or contract basis": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "68.32", description: "Other real estate services on a fee or contract basis" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "68.32", description: "Other real estate activities on a fee or contract basis" },
            UK_SIC: { code: "68.32", description: "Management of real estate on a fee or contract basis" },
        },
        "Legal activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "69.10", description: "Legal services" },
            ISIC: { code: "6910", description: "Legal activities" },
            NACE: { code: "69.10", description: "Legal activities" },
            UK_SIC: { code: "69.10", description: "Legal activities" },
        },
        "Accounting, bookkeeping and auditing activities; tax consultancy": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "69.20", description: "Accounting, bookkeeping and auditing services; tax consultancy services" },
            ISIC: { code: "6920", description: "Accounting, bookkeeping and auditing activities; tax consultancy" },
            NACE: { code: "69.20", description: "Accounting, bookkeeping and auditing activities; tax consultancy" },
            UK_SIC: { code: "69.20", description: "Accounting, bookkeeping and auditing activities; tax consultancy" },
        },
        "Activities of head offices": {
            SCAT: { code: "204", description: "Offices (Headquarters/Institutional)" },
            CPA: { code: "70.10", description: "Services of head offices" },
            ISIC: { code: "7010", description: "Activities of head offices" },
            NACE: { code: "70.10", description: "Activities of head offices" },
            UK_SIC: { code: "70.10", description: "Activities of head offices" },
        },
        "Business and other management consultancy activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "70.20", description: "Business and other management consultancy services" },
            ISIC: { code: "7020", description: "Business and other management consultancy activities" },
            NACE: { code: "70.20", description: "Business and other management consultancy activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Architectural activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "71.11", description: "Architectural services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "71.11", description: "Architectural activities" },
            UK_SIC: { code: "71.11", description: "Architectural activities" },
        },
        "Engineering activities and related technical consultancy": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "71.12", description: "Engineering services and related technical consultancy" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "71.12", description: "Engineering activities and related technical consultancy" },
            UK_SIC: { code: "71.12", description: "Engineering activities and related technical consultancy" },
        },
        "Technical testing and analysis": {
            SCAT: { code: "144", description: "Laboratories" },
            CPA: { code: "71.20", description: "Technical testing and analysis services" },
            ISIC: { code: "7120", description: "Technical testing and analysis" },
            NACE: { code: "71.20", description: "Technical testing and analysis" },
            UK_SIC: { code: "71.20", description: "Technical testing and analysis" },
        },
        "Research and experimental development on natural sciences and engineering": {
            SCAT: { code: "402", description: "Agricultural Research Centres" },
            CPA: { code: "72.10", description: "Research and development services on natural sciences, engineering and technology" },
            ISIC: { code: "7210", description: "Research and experimental development on natural sciences and engineering" },
            NACE: { code: "72.10", description: "Research and experimental development on natural sciences and engineering" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Research and experimental development on social sciences and humanities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "72.20", description: "Research and development services on social sciences and humanities" },
            ISIC: { code: "7220", description: "Research and experimental development on social sciences and humanities" },
            NACE: { code: "72.20", description: "Research and experimental development on social sciences and humanities" },
            UK_SIC: { code: "72.20", description: "Research and experimental development on social sciences and humanities" },
        },
        "Activities of advertising agencies": {
            SCAT: { code: "3\n706\n707\n708\n711\n188", description: "Advertising Right\nAdvertising Displays On Pavements & Bus Shelters\nAdvertising Displays At Superstores\nAdvertising Displays From Phone Booths\nAdvertising Displays From Agricultural Land\nModel Villages" },
            CPA: { code: "73.11", description: "Services provided by advertising agencies" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "73.11", description: "Activities of advertising agencies" },
            UK_SIC: { code: "73.11", description: "Advertising agencies" },
        },
        "Media representation": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "73.12", description: "Media representation services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "73.12", description: "Media representation" },
            UK_SIC: { code: "73.12", description: "Media representation" },
        },
        "Market research and public opinion polling": {
            SCAT: { code: "146", description: "Land Used For Advertising" },
            CPA: { code: "73.20", description: "Market research and public opinion polling services" },
            ISIC: { code: "7320", description: "Market research and public opinion polling activities " },
            NACE: { code: "73.20", description: "Market research and public opinion polling" },
            UK_SIC: { code: "73.20", description: "Market research and public opinion polling" },
        },
        "Public relations and communication activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "73.30", description: "Public relations and communication services" },
            ISIC: { code: "7330", description: "Public relations activities" },
            NACE: { code: "73.30", description: "Public relations and communication activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Industrial product and fashion design activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.11", description: "Industrial product and fashion design services and design originals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "74.11", description: "Industrial product and fashion design activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Graphic design and visual communication activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.12", description: "Graphic design and visual communication services and design originals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "74.12", description: "Graphic design and visual communication activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Interior design activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.13", description: "Interior design services and design originals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "74.13", description: "Interior design activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other specialised design activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.14", description: "Other specialised design services and design originals" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "74.14", description: "Other specialised design activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Photographic activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.20", description: "Photographic services" },
            ISIC: { code: "7420", description: "Photographic activities " },
            NACE: { code: "74.20", description: "Photographic activities" },
            UK_SIC: { code: "74.20", description: "Photographic activities" },
        },
        "Translation and interpretation activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.30", description: "Translation and interpretation services" },
            ISIC: { code: "7430", description: "Translation and interpretation activities " },
            NACE: { code: "74.30", description: "Translation and interpretation activities" },
            UK_SIC: { code: "74.30", description: "Translation and interpretation activities" },
        },
        "Patent brokering and marketing service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.91", description: "Patent brokering, marketing and management services" },
            ISIC: { code: "7491", description: "Patent brokering and marketing service activities" },
            NACE: { code: "74.91", description: "Patent brokering and marketing service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "All other professional, scientific and technical activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "74.99", description: "All other professional, scientific and technical services n.e.c." },
            ISIC: { code: "7499", description: "All other professional, scientific and technical activities not elsewhere classified " },
            NACE: { code: "74.99", description: "All other professional, scientific and technical activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Veterinary activities": {
            SCAT: { code: "292\n756", description: "Veterinary Clinics / Animal Clinics\nEquestrian Hospitals" },
            CPA: { code: "75.00", description: "Veterinary services" },
            ISIC: { code: "7500", description: "Veterinary activities " },
            NACE: { code: "75.00", description: "Veterinary activities" },
            UK_SIC: { code: "75.00", description: "Veterinary activities" },
        },
        "Rental and leasing of cars and light motor vehicles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.11", description: "Rental and leasing services of cars and light motor vehicles" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.11", description: "Rental and leasing of cars and light motor vehicles" },
            UK_SIC: { code: "77.11", description: "Renting and leasing of cars and light motor vehicles" },
        },
        "Rental and leasing of trucks": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.12", description: "Rental and leasing services of trucks" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.12", description: "Rental and leasing of trucks" },
            UK_SIC: { code: "77.12", description: "Renting and leasing of trucks" },
        },
        "Rental and leasing of recreational and sports goods": {
            SCAT: { code: "75\n734", description: "Wedding & Function Venues\nWedding and Function Venue" },
            CPA: { code: "77.21", description: "Rental and leasing services of recreational and sports goods" },
            ISIC: { code: "7721", description: "Rental and leasing of recreational and sports goods" },
            NACE: { code: "77.21", description: "Rental and leasing of recreational and sports goods" },
            UK_SIC: { code: "77.21", description: "Renting and leasing of recreational and sports goods" },
        },
        "Rental and leasing of other personal and household goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.22", description: "Rental and leasing services of other personal and household goods" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.22", description: "Rental and leasing of other personal and household goods" },
            UK_SIC: { code: "77.22", description: "Renting of video tapes and disks" },
        },
        "Rental and leasing of agricultural machinery and equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.31", description: "Rental and leasing services of agricultural machinery and equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.31", description: "Rental and leasing of agricultural machinery and equipment" },
            UK_SIC: { code: "77.31", description: "Renting and leasing of agricultural machinery and equipment" },
        },
        "Rental and leasing of construction and civil engineering machinery and equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.32", description: "Rental and leasing services of construction and civil engineering machinery and equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.32", description: "Rental and leasing of construction and civil engineering machinery and equipment" },
            UK_SIC: { code: "77.32", description: "Renting and leasing of construction and civil engineering machinery and equipment" },
        },
        "Rental and leasing of office machinery, equipment and computers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.33", description: "Rental and leasing services of office machinery, equipment and computers" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.33", description: "Rental and leasing of office machinery, equipment and computers" },
            UK_SIC: { code: "77.33", description: "Renting and leasing of office machinery and equipment (including computers)" },
        },
        "Rental and leasing of water transport equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.34", description: "Rental and leasing services of water transport equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.34", description: "Rental and leasing of water transport equipment" },
            UK_SIC: { code: "77.34", description: "Renting and leasing of water transport equipment" },
        },
        "Rental and leasing of air transport equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.35", description: "Rental and leasing services of air transport equipment" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.35", description: "Rental and leasing of air transport equipment" },
            UK_SIC: { code: "77.35", description: "Renting and leasing of air transport equipment" },
        },
        "Rental and leasing of other machinery, equipment and tangible goods not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.39", description: "Rental and leasing services of other machinery, equipment and tangible goods n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "77.39", description: "Rental and leasing of other machinery, equipment and tangible goods not elsewhere classified" },
            UK_SIC: { code: "77.39", description: "Renting and leasing of other machinery, equipment and tangible goods n.e.c." },
        },
        "Leasing of intellectual property and similar products, except copyrighted works": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.40", description: "Licensing services of intellectual property and similar products, except copyrighted works" },
            ISIC: { code: "7740", description: "Leasing of intellectual property and similar products, except copyrighted works" },
            NACE: { code: "77.40", description: "Leasing of intellectual property and similar products, except copyrighted works" },
            UK_SIC: { code: "77.40", description: "Leasing of intellectual property and similar products, except copyrighted works" },
        },
        "Intermediation service activities for rental and leasing of cars, motorhomes and trailers": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.51", description: "Intermediation services of rental and leasing of cars, motorhomes and trailers" },
            ISIC: { code: "7751", description: "Intermediation service activities for rental and leasing of cars, motorhomes and trailers" },
            NACE: { code: "77.51", description: "Intermediation service activities for rental and leasing of cars, motorhomes and trailers" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for rental and leasing of other tangible goods and non-financial intangible assets": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "77.52", description: "Intermediation services of rental and leasing of other tangible goods and non-financial intangible assets" },
            ISIC: { code: "7752", description: "Intermediation service activities for rental and leasing of other tangible goods and non-financial intangible assets" },
            NACE: { code: "77.52", description: "Intermediation service activities for rental and leasing of other tangible goods and non-financial intangible assets" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of employment placement agencies": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "78.10", description: "Services provided by employment placement agencies" },
            ISIC: { code: "7810", description: "Activities of employment placement agencies" },
            NACE: { code: "78.10", description: "Activities of employment placement agencies" },
            UK_SIC: { code: "78.10", description: "Activities of employment placement agencies" },
        },
        "Temporary employment agency activities and other human resource provisions": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "78.20", description: "Temporary employment agency services and other human resource provisions" },
            ISIC: { code: "7820", description: "Temporary employment agency activities and other human resource provisions" },
            NACE: { code: "78.20", description: "Temporary employment agency activities and other human resource provisions" },
            UK_SIC: { code: "78.20", description: "Temporary employment agency activities" },
        },
        "Travel agency activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "79.11", description: "Travel agency services" },
            ISIC: { code: "7911", description: "Travel agency activities" },
            NACE: { code: "79.11", description: "Travel agency activities" },
            UK_SIC: { code: "79.11", description: "Travel agency activities" },
        },
        "Tour operator activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "79.12", description: "Tour operator services" },
            ISIC: { code: "7912", description: "Tour operator activities" },
            NACE: { code: "79.12", description: "Tour operator activities" },
            UK_SIC: { code: "79.12", description: "Tour operator activities" },
        },
        "Other reservation service and related activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "79.90", description: "Other reservation services and related services" },
            ISIC: { code: "7990", description: "Other travel related activities" },
            NACE: { code: "79.90", description: "Other reservation service and related activities" },
            UK_SIC: { code: "79.90", description: "Other reservation service and related activities" },
        },
        "Investigation and private security activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "80.01", description: "Investigation and private security services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "80.01", description: "Investigation and private security activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Security activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "80.09", description: "Security services n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "80.09", description: "Security activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Combined facilities support activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "81.10", description: "Combined facilities support services" },
            ISIC: { code: "8110", description: "Combined facilities support activities" },
            NACE: { code: "81.10", description: "Combined facilities support activities" },
            UK_SIC: { code: "81.10", description: "Combined facilities support activities" },
        },
        "General cleaning of buildings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "81.21", description: "General cleaning services of buildings" },
            ISIC: { code: "8121", description: "General cleaning of buildings" },
            NACE: { code: "81.21", description: "General cleaning of buildings" },
            UK_SIC: { code: "81.21", description: "General cleaning of buildings" },
        },
        "Other building and industrial cleaning activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "81.22", description: "Other building and industrial cleaning services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "81.22", description: "Other building and industrial cleaning activities" },
            UK_SIC: { code: "81.22", description: "Other building and industrial cleaning activities" },
        },
        "Other cleaning activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "81.23", description: "Other cleaning services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "81.23", description: "Other cleaning activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Landscape service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "81.30", description: "Landscape services" },
            ISIC: { code: "8130", description: "Landscape service activities" },
            NACE: { code: "81.30", description: "Landscape service activities" },
            UK_SIC: { code: "81.30", description: "Landscape service activities" },
        },
        "Office administrative and support activities": {
            SCAT: { code: "723", description: "Serviced Offices" },
            CPA: { code: "82.10", description: "Office administrative and support services" },
            ISIC: { code: "8210", description: "Office administrative and support activities" },
            NACE: { code: "82.10", description: "Office administrative and support activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of call centres": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "82.20", description: "Call centre services" },
            ISIC: { code: "8220", description: "Activities of call centres" },
            NACE: { code: "82.20", description: "Activities of call centres" },
            UK_SIC: { code: "82.20", description: "Activities of call centres" },
        },
        "Organisation of conventions and trade shows": {
            SCAT: { code: "74", description: "Conference & Exhibition Centres" },
            CPA: { code: "82.30", description: "Organisation of conventions and trade shows services" },
            ISIC: { code: "8230", description: "Organization of conventions and trade shows" },
            NACE: { code: "82.30", description: "Organisation of conventions and trade shows" },
            UK_SIC: { code: "82.30", description: "Organisation of conventions and trade shows" },
        },
        "Intermediation service activities for business support service activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "82.40", description: "Intermediation services of business support services n.e.c." },
            ISIC: { code: "8240", description: "Intermediation service activities for business support activities not elsewhere classified, except financial intermediation" },
            NACE: { code: "82.40", description: "Intermediation service activities for business support service activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of collection agencies and credit bureaus": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "82.91", description: "Services of collection agencies and credit bureaus" },
            ISIC: { code: "8291", description: "Activities of collection agencies and credit bureaus" },
            NACE: { code: "82.91", description: "Activities of collection agencies and credit bureaus" },
            UK_SIC: { code: "82.91", description: "Activities of collection agencies and credit bureaus" },
        },
        "Packaging activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "82.92", description: "Packaging services" },
            ISIC: { code: "8292", description: "Packaging activities" },
            NACE: { code: "82.92", description: "Packaging activities" },
            UK_SIC: { code: "82.92", description: "Packaging activities" },
        },
        "Other business support service activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "82.99", description: "Other business support services n.e.c." },
            ISIC: { code: "8299", description: "Other business support service activities not elsewhere classified" },
            NACE: { code: "82.99", description: "Other business support service activities not elsewhere classified" },
            UK_SIC: { code: "82.99", description: "Other business support service activities n.e.c." },
        },
        "General public administration activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "84.11", description: "General public administration services" },
            ISIC: { code: "8411", description: "General public administration activities" },
            NACE: { code: "84.11", description: "General public administration activities" },
            UK_SIC: { code: "84.11", description: "General public administration activities" },
        },
        "Regulation of health care, education, cultural services and other social services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "84.12", description: "Administrative services of the regulation of health care, education, cultural services and other social services" },
            ISIC: { code: "8412", description: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security and environment" },
            NACE: { code: "84.12", description: "Regulation of health care, education, cultural services and other social services" },
            UK_SIC: { code: "84.12", description: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security" },
        },
        "Regulation of and contribution to more efficient operation of businesses": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "84.13", description: "Administrative services of the regulation of and contribution to more efficient operation of businesses" },
            ISIC: { code: "8413", description: "Regulation of the activities of providing environmental services" },
            NACE: { code: "84.13", description: "Regulation of and contribution to more efficient operation of businesses" },
            UK_SIC: { code: "84.13", description: "Regulation of and contribution to more efficient operation of businesses" },
        },
        "Foreign affairs": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "84.21", description: "Foreign affairs services" },
            ISIC: { code: "8421", description: "Foreign affairs" },
            NACE: { code: "84.21", description: "Foreign affairs" },
            UK_SIC: { code: "84.21", description: "Foreign affairs" },
        },
        "Defence activities": {
            SCAT: { code: "15", description: "Army Hereditaments" },
            CPA: { code: "84.22", description: "Defence services" },
            ISIC: { code: "8422", description: "Defence activities" },
            NACE: { code: "84.22", description: "Defence activities" },
            UK_SIC: { code: "84.22", description: "Defence activities" },
        },
        "Justice and judicial activities": {
            SCAT: { code: "414\n415", description: "Courts (Contractors Valuation)\nCourts (Rental Valuation)" },
            CPA: { code: "84.23", description: "Justice and judicial services" },
            ISIC: { code: "8423", description: "Public order and safety activities" },
            NACE: { code: "84.23", description: "Justice and judicial activities" },
            UK_SIC: { code: "84.23", description: "Justice and judicial activities" },
        },
        "Public order and safety activities": {
            SCAT: { code: "215", description: "Police Stations" },
            CPA: { code: "84.24", description: "Public order and safety services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "84.24", description: "Public order and safety activities" },
            UK_SIC: { code: "84.24", description: "Public order and safety activities" },
        },
        "Fire service activities": {
            SCAT: { code: "101", description: "Fire Stations" },
            CPA: { code: "84.25", description: "Fire brigade services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "84.25", description: "Fire service activities" },
            UK_SIC: { code: "84.25", description: "Fire service activities" },
        },
        "Compulsory social security activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "84.30", description: "Compulsory social security services" },
            ISIC: { code: "8430", description: "Compulsory social security activities" },
            NACE: { code: "84.30", description: "Compulsory social security activities" },
            UK_SIC: { code: "84.30", description: "Compulsory social security activities" },
        },
        "Pre-primary education": {
            SCAT: { code: "85\n505", description: "Day Nurseries/Play Schools\nNurseries/Creches Within/Part of Specialist Property" },
            CPA: { code: "85.10", description: "Pre-primary education services" },
            ISIC: { code: "8510", description: "Pre-primary education " },
            NACE: { code: "85.10", description: "Pre-primary education" },
            UK_SIC: { code: "85.10", description: "Pre-primary education" },
        },
        "Primary education": {
            SCAT: { code: "159", description: "Local Authority Schools (National Scheme)" },
            CPA: { code: "85.20", description: "Primary education services" },
            ISIC: { code: "8520", description: "Primary education " },
            NACE: { code: "85.20", description: "Primary education" },
            UK_SIC: { code: "85.20", description: "Primary education" },
        },
        "General secondary education": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.31", description: "General secondary education services" },
            ISIC: { code: "8531", description: "General secondary education " },
            NACE: { code: "85.31", description: "General secondary education" },
            UK_SIC: { code: "85.31", description: "General secondary education" },
        },
        "Vocational secondary education": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.32", description: "Vocational secondary education services" },
            ISIC: { code: "8532", description: "Vocational secondary education" },
            NACE: { code: "85.32", description: "Vocational secondary education" },
            UK_SIC: { code: "85.32", description: "Technical and vocational secondary education" },
        },
        "Post-secondary non-tertiary education": {
            SCAT: { code: "65\n206", description: "Colleges of Further Education (National Scheme)\nOxbridge Colleges" },
            CPA: { code: "85.33", description: "Post-secondary non-tertiary education services" },
            ISIC: { code: "8533", description: "Post-secondary non-tertiary education " },
            NACE: { code: "85.33", description: "Post-secondary non-tertiary education" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Tertiary education": {
            SCAT: { code: "439\n440", description: "University - Ancillary Land or Buildings\nUniversity Occupation Within Hospitals" },
            CPA: { code: "85.40", description: "Tertiary education services" },
            ISIC: { code: "8540", description: "Tertiary education" },
            NACE: { code: "85.40", description: "Tertiary education" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Sports and recreation education": {
            SCAT: { code: "84", description: "Dance Schools & Centres" },
            CPA: { code: "85.51", description: "Sports and recreation education services" },
            ISIC: { code: "8551", description: " Sports and recreation education " },
            NACE: { code: "85.51", description: "Sports and recreation education" },
            UK_SIC: { code: "85.51", description: "Sports and recreation education" },
        },
        "Cultural education": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.52", description: "Cultural education services" },
            ISIC: { code: "8552", description: " Cultural education" },
            NACE: { code: "85.52", description: "Cultural education" },
            UK_SIC: { code: "85.52", description: "Cultural education" },
        },
        "Driving school activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.53", description: "Driving school services" },
            ISIC: { code: "8553", description: "Driving school activities" },
            NACE: { code: "85.53", description: "Driving school activities" },
            UK_SIC: { code: "85.53", description: "Driving school activities" },
        },
        "Other education not elsewhere classified": {
            SCAT: { code: "223\n995\n428\n285\n286\n288\n99", description: "Public and Independent Schools (National Scheme)\nEducational Miscellaneous\nPolice Training Colleges\nTraining Centre (Non Residential)\nTraining Centre (Residential)\nUniversities   (Excluding   Oxbridge) (National Scheme)\nField Study, Activity and Adventure Centres" },
            CPA: { code: "85.59", description: "Other education services n.e.c." },
            ISIC: { code: "8559", description: "Other education not elsewhere classified" },
            NACE: { code: "85.59", description: "Other education not elsewhere classified" },
            UK_SIC: { code: "85.59", description: "Other education n.e.c." },
        },
        "Intermediation service activities for courses and tutors": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.61", description: "Intermediation services of courses and tutors" },
            ISIC: { code: "8561", description: "Intermediation service activities for courses and tutors" },
            NACE: { code: "85.61", description: "Intermediation service activities for courses and tutors" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Educational support activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "85.69", description: "Educational support services n.e.c." },
            ISIC: { code: "8569", description: "Other educational support activities" },
            NACE: { code: "85.69", description: "Educational support activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Hospital activities": {
            SCAT: { code: "135\n436\n437", description: "Hospitals & Clinics (Private) (National Scheme)\nSurgeries, Clinics, Health Centres (Contractors Valuation)\nSurgeries,  Clinics,  Health  Centres (Rental Valuation)" },
            CPA: { code: "86.10", description: "Hospital services" },
            ISIC: { code: "8610", description: "Hospital activities" },
            NACE: { code: "86.10", description: "Hospital activities" },
            UK_SIC: { code: "86.10", description: "Hospital activities" },
        },
        "General medical practice activities": {
            SCAT: { code: "134\n750\n751", description: "Hospitals & Clinics NHS (National Scheme)\nNHS Clinics & Health Centres (Contractors)\nNHS Clinics & Health Centres (Rentals)" },
            CPA: { code: "86.21", description: "General medical practice services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.21", description: "General medical practice activities" },
            UK_SIC: { code: "86.21", description: "General medical practice activities" },
        },
        "Medical specialists activities": {
            SCAT: { code: "752\n753\n754\n755", description: "Surgeries (Other Than GP/NHS) (Contractors)\nSurgeries (Other Than GP/NHS) (Rentals)\nGP Surgeries (Contractors)\nGP Surgeries (Rentals)" },
            CPA: { code: "86.22", description: "Medical specialists services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.22", description: "Medical specialists activities" },
            UK_SIC: { code: "86.22", description: "Specialist medical practice activities" },
        },
        "Dental practice care activities": {
            SCAT: { code: "271", description: "Surgeries, Clinics, Health Centre (incl Dentists etc)" },
            CPA: { code: "86.23", description: "Dental practice care services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.23", description: "Dental practice care activities" },
            UK_SIC: { code: "86.23", description: "Dental practice activities" },
        },
        "Diagnostic imaging services and medical laboratory activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.91", description: "Diagnostic imaging services and medical laboratory services" },
            ISIC: { code: "8691", description: "Intermediation service activities for medical, dental, and other human health services" },
            NACE: { code: "86.91", description: "Diagnostic imaging services and medical laboratory activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Patient transportation by ambulance": {
            SCAT: { code: "10", description: "Ambulance Stations" },
            CPA: { code: "86.92", description: "Patient transportation services by ambulance" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.92", description: "Patient transportation by ambulance" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of psychologists and psychotherapists, except medical doctors": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.93", description: "Mental health services of psychologists and psychotherapists, except medical doctors" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.93", description: "Activities of psychologists and psychotherapists, except medical doctors" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Nursing and midwifery activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.94", description: "Nursing and midwifery services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.94", description: "Nursing and midwifery activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Physiotherapy activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.95", description: "Physiotherapy services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.95", description: "Physiotherapy activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Traditional, complementary and alternative medicine activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.96", description: "Traditional, complementary and alternative medicine services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.96", description: "Traditional, complementary and alternative medicine activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for medical, dental and other human health services": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "86.97", description: "Intermediation services of medical, dental and other human health services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "86.97", description: "Intermediation service activities for medical, dental and other human health services" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other human health activities not elsewhere classified": {
            SCAT: { code: "125", description: "Health Farms" },
            CPA: { code: "86.99", description: "Other human health services n.e.c." },
            ISIC: { code: "8699", description: "Other human health activities n.e.c" },
            NACE: { code: "86.99", description: "Other human health activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Residential nursing care activities": {
            SCAT: { code: "201", description: "Nursing Homes (Inc. Old Peoples Homes)" },
            CPA: { code: "87.10", description: "Residential nursing care services" },
            ISIC: { code: "8710", description: "Residential nursing care activities " },
            NACE: { code: "87.10", description: "Residential nursing care activities" },
            UK_SIC: { code: "87.10", description: "Residential nursing care activities" },
        },
        "Residential care activities for persons living with or having a diagnosis of a mental illness or substance abuse": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "87.20", description: "Residential care services of persons living with or having a diagnosis of a mental illness or substance abuse" },
            ISIC: { code: "8720", description: "Residential care activities for persons living with or having a diagnosis of a mental illness or substance abuse" },
            NACE: { code: "87.20", description: "Residential care activities for persons living with or having a diagnosis of a mental illness or substance abuse" },
            UK_SIC: { code: "87.20", description: "Residential care activities for learning disabilities, mental health and substance abuse" },
        },
        "Residential care activities for older persons or persons with physical disabilities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "87.30", description: "Residential care services of older persons or persons with physical disabilities" },
            ISIC: { code: "8730", description: "Residential care activities for older persons or persons with physical disabilities" },
            NACE: { code: "87.30", description: "Residential care activities for older persons or persons with physical disabilities" },
            UK_SIC: { code: "87.30", description: "Residential care activities for the elderly and disabled" },
        },
        "Intermediation service activities for residential care activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "87.91", description: "Intermediation services of residential care services" },
            ISIC: { code: "8791", description: "Intermediation service activities for residential care activities" },
            NACE: { code: "87.91", description: "Intermediation service activities for residential care activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other residential care activities not elsewhere classified": {
            SCAT: { code: "740", description: "Secure Childrens Homes" },
            CPA: { code: "87.99", description: "Other residential care services n.e.c." },
            ISIC: { code: "8799", description: "Other residential care activities not elsewhere classified" },
            NACE: { code: "87.99", description: "Other residential care activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Social work activities without accommodation for older persons or persons with disabilities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "88.10", description: "Social work services without accommodation for older persons or persons with disabilities" },
            ISIC: { code: "8810", description: "Social work activities without accommodation for older persons or persons with disabilities" },
            NACE: { code: "88.10", description: "Social work activities without accommodation for older persons or persons with disabilities" },
            UK_SIC: { code: "88.10", description: "Social work activities without accommodation for the elderly and disabled" },
        },
        "Child day-care activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "88.91", description: "Child day-care services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "88.91", description: "Child day-care activities" },
            UK_SIC: { code: "88.91", description: "Child day-care activities" },
        },
        "Other social work activities without accommodation not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "88.99", description: "Other social work services without accommodation n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "88.99", description: "Other social work activities without accommodation not elsewhere classified" },
            UK_SIC: { code: "88.99", description: "Other social work activities without accommodation n.e.c." },
        },
        "Literary creation and musical composition activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.11", description: "Literary creation and musical composition services" },
            ISIC: { code: "9011", description: "Literary creation and musical composition activities" },
            NACE: { code: "90.11", description: "Literary creation and musical composition activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Visual arts creation activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.12", description: "Visual arts creation services" },
            ISIC: { code: "9012", description: "Visual arts creation activities " },
            NACE: { code: "90.12", description: "Visual arts creation activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other arts creation activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.13", description: "Other arts creation services" },
            ISIC: { code: "9013", description: "Other arts creation activities " },
            NACE: { code: "90.13", description: "Other arts creation activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Activities of performing arts": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.20", description: "Services of performing arts" },
            ISIC: { code: "9020", description: "Activities of performing arts " },
            NACE: { code: "90.20", description: "Activities of performing arts" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Operation of arts facilities and sites": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.31", description: "Operation services of arts facilities and sites" },
            ISIC: { code: "9031", description: "Operation of arts facilities and sites " },
            NACE: { code: "90.31", description: "Operation of arts facilities and sites" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other support activities to arts and performing arts": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "90.39", description: "Other support services to arts and performing arts" },
            ISIC: { code: "9039", description: "Other support activities to arts creation and performing arts" },
            NACE: { code: "90.39", description: "Other support activities to arts and performing arts" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Library activities": {
            SCAT: { code: "156", description: "Libraries" },
            CPA: { code: "91.11", description: "Library services" },
            ISIC: { code: "9111", description: "Library activities" },
            NACE: { code: "91.11", description: "Library activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Archive activities": {
            SCAT: { code: "404", description: "Archives" },
            CPA: { code: "91.12", description: "Archive services" },
            ISIC: { code: "9112", description: "Archive activities" },
            NACE: { code: "91.12", description: "Archive activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Museum and collection activities": {
            SCAT: { code: "195\n196", description: "Museums and Art Galleries (Contractors)\nMuseums and Art Galleries (NonContractors)" },
            CPA: { code: "91.21", description: "Museum and collection services" },
            ISIC: { code: "9121", description: "Museum and collection activities " },
            NACE: { code: "91.21", description: "Museum and collection activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Historical site and monument activities": {
            SCAT: { code: "735", description: "Historic Property (National Trust/English Heritage)" },
            CPA: { code: "91.22", description: "Historical site and monument services" },
            ISIC: { code: "9122", description: "Historical site and monument activities" },
            NACE: { code: "91.22", description: "Historical site and monument activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Conservation, restoration and other support activities for cultural heritage": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "91.30", description: "Conservation, restoration and other support services of cultural heritage" },
            ISIC: { code: "9130", description: "Conservation, restoration and other support activities for cultural heritage " },
            NACE: { code: "91.30", description: "Conservation, restoration and other support activities for cultural heritage" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Botanical and zoological garden activities": {
            SCAT: { code: "257\n26", description: "Sports & Leisure Centres (LA) (Dry Only) (National Scheme)\nBird Sanctuaries" },
            CPA: { code: "91.41", description: "Botanical and zoological garden services" },
            ISIC: { code: "9141", description: "Botanical and zoological garden activities " },
            NACE: { code: "91.41", description: "Botanical and zoological garden activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Nature reserve activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "91.42", description: "Nature reserve services" },
            ISIC: { code: "9142", description: "Nature reserve activities" },
            NACE: { code: "91.42", description: "Nature reserve activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Gambling and betting activities": {
            SCAT: { code: "24\n25\n49", description: "Betting Offices\nBingo Halls (National Scheme)\nCasinos and Gambling Clubs" },
            CPA: { code: "92.00", description: "Gambling and betting services" },
            ISIC: { code: "9200", description: "Gambling and betting activities" },
            NACE: { code: "92.00", description: "Gambling and betting activities" },
            UK_SIC: { code: "92.00", description: "Gambling and betting activities" },
        },
        "Operation of sports facilities": {
            SCAT: { code: "28\n29\n30\n256\n261\n262\n293\n405\n420\n247\n107\n108\n410\n109\n715\n81\n82\n83\n277\n278\n416\n503\n117\n118\n145\n252\n254\n241\n242\n191\n216\n229\n263\n272\n273\n739\n116\n140", description: "Bowling Alleys\nBowling Centres (Indoor)\nBowling Greens (Outdoor)\nSporting Rights\nSports Grounds\nSports Stadia\nVillage Halls, Scout Huts, Cadet Huts Etc\nBoathouses\nLifeboat Stations\nShip Building Yards\nFootball Grounds\nFootball Pitches\nChanging Rooms\nFootball Stadia\nFootball Training Grounds\nCricket Centres\nCricket Grounds (County)\nCricket Grounds/Pitches (Non- County)\nTennis Centres\nTennis Courts/Clubs\nGymnasia/Fitness Suites\nGymnasia/Fitness Suites Within/Part of Specialist Property\nGolf Courses\nGolf Driving Ranges\nLakes With Water Sport Facilities\nSki Centres\nSpeedway Racetracks\nRugby League Grounds\nRugby Union Grounds\nMotor Racetracks\nPolo Grounds\nRacing Stables (National Scheme)\nSquash Courts\nSwimming Pools (Local Authority)\nSwimming Pools (Private)\nSoccer Centres\nGo Kart Rinks\nIce Rinks" },
            CPA: { code: "93.11", description: "Sports facility operation services" },
            ISIC: { code: "9311", description: "Operation of sports facilities" },
            NACE: { code: "93.11", description: "Operation of sports facilities" },
            UK_SIC: { code: "93.11", description: "Operation of sports facilities" },
        },
        "Activities of sports clubs": {
            SCAT: { code: "61\n253\n278", description: "Licensed Sports, Social & Private Members Clubs\nSnooker Halls/Clubs\nTennis Courts/Clubs" },
            CPA: { code: "93.12", description: "Services of sports clubs" },
            ISIC: { code: "9312", description: "Activities of sports clubs " },
            NACE: { code: "93.12", description: "Activities of sports clubs" },
            UK_SIC: { code: "93.12", description: "Activities of sport clubs" },
        },
        "Activities of fitness centres": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "93.13", description: "Services of fitness centres" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "93.13", description: "Activities of fitness centres" },
            UK_SIC: { code: "93.13", description: "Fitness facilities" },
        },
        "Sports activities not elsewhere classified": {
            SCAT: { code: "256\n121", description: "Sporting Rights\nGreyhound Racetracks" },
            CPA: { code: "93.19", description: "Other sports services" },
            ISIC: { code: "9319", description: "Other sports activities " },
            NACE: { code: "93.19", description: "Sports activities not elsewhere classified" },
            UK_SIC: { code: "93.19", description: "Other sports activities" },
        },
        "Activities of amusement parks and theme parks": {
            SCAT: { code: "11\n12\n14\n257\n258\n259\n260\n509\n280\n304", description: "Amusement Arcades\nAmusement Parks\nArenas\nSports & Leisure Centres (LA) (Dry Only) (National Scheme)\nSports & Leisure Centres (LA) (Wet & Dry) (National Scheme)\nSports & Leisure Centres (Private)(Dry Only)\nSports & Leisure Centres (Private)(Wet & Dry)\nSports & Leisure Centres Within/Part of Specialist Property\nTheme Parks\nZoos & Safari Parks" },
            CPA: { code: "93.21", description: "Amusement park and theme park services" },
            ISIC: { code: "9321", description: "Activities of amusement parks and theme parks" },
            NACE: { code: "93.21", description: "Activities of amusement parks and theme parks" },
            UK_SIC: { code: "93.21", description: "Activities of amusement parks and theme parks" },
        },
        "Amusement and recreation activities not elsewhere classified": {
            SCAT: { code: "993\n403\n56\n279\n199\n70\n250\n4\n164", description: "Leisure Miscellaneous\nAquaria\nCinemas (National Scheme)\nTheatres (National Scheme)\nNight Clubs & Discotheques\nConcert Halls (National Scheme)\nShowhouses (National Scheme)\nAgricultural Showgrounds (National Scheme)\nMarinas (National Scheme)" },
            CPA: { code: "93.29", description: "Other amusement and recreation services" },
            ISIC: { code: "9329", description: "Other amusement and recreation activities" },
            NACE: { code: "93.29", description: "Amusement and recreation activities not elsewhere classified" },
            UK_SIC: { code: "93.29", description: "Other amusement and recreation activities" },
        },
        "Activities of business and employers membership organisations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.11", description: "Services furnished by business and employers membership organisations" },
            ISIC: { code: "9411", description: "Activities of business and employers membership organizations" },
            NACE: { code: "94.11", description: "Activities of business and employers membership organisations" },
            UK_SIC: { code: "94.11", description: "Activities of business and employers membership organisations" },
        },
        "Activities of professional membership organisations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.12", description: "Services furnished by professional membership organisations" },
            ISIC: { code: "9412", description: "Activities of professional membership organizations" },
            NACE: { code: "94.12", description: "Activities of professional membership organisations" },
            UK_SIC: { code: "94.12", description: "Activities of professional membership organisations" },
        },
        "Activities of trade unions": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.20", description: "Services furnished by trade unions" },
            ISIC: { code: "9420", description: "Activities of trade unions" },
            NACE: { code: "94.20", description: "Activities of trade unions" },
            UK_SIC: { code: "94.20", description: "Activities of trade unions" },
        },
        "Activities of religious organisations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.91", description: "Services furnished by religious organisations" },
            ISIC: { code: "9491", description: "Activities of religious organizations" },
            NACE: { code: "94.91", description: "Activities of religious organisations" },
            UK_SIC: { code: "94.91", description: "Activities of religious organisations" },
        },
        "Activities of political organisations": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.92", description: "Services furnished by political organisations" },
            ISIC: { code: "9492", description: "Activities of political organizations" },
            NACE: { code: "94.92", description: "Activities of political organisations" },
            UK_SIC: { code: "94.92", description: "Activities of political organisations" },
        },
        "Activities of other membership organisations not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "94.99", description: "Services furnished by other membership organisations n.e.c." },
            ISIC: { code: "9499", description: "Activities of other membership organizations not elsewhere classified" },
            NACE: { code: "94.99", description: "Activities of other membership organisations not elsewhere classified" },
            UK_SIC: { code: "94.99", description: "Activities of other membership organisations n.e.c." },
        },
        "Repair and maintenance of computers and communication equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.10", description: "Repair and maintenance services of computers and communication equipment" },
            ISIC: { code: "9510", description: "Repair and maintenance of computers and communication equipment" },
            NACE: { code: "95.10", description: "Repair and maintenance of computers and communication equipment" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Repair and maintenance of consumer electronics": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.21", description: "Repair and maintenance services of consumer electronics" },
            ISIC: { code: "9521", description: "Repair and maintenance of consumer electronics" },
            NACE: { code: "95.21", description: "Repair and maintenance of consumer electronics" },
            UK_SIC: { code: "95.21", description: "Repair of consumer electronics" },
        },
        "Repair and maintenance of household appliances and home and garden equipment": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.22", description: "Repair and maintenance services of household appliances and home and garden equipment" },
            ISIC: { code: "9522", description: "Repair and maintenance of household appliances and home and garden equipment" },
            NACE: { code: "95.22", description: "Repair and maintenance of household appliances and home and garden equipment" },
            UK_SIC: { code: "95.22", description: "Repair of household appliances and home and garden equipment" },
        },
        "Repair and maintenance of footwear and leather goods": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.23", description: "Repair and maintenance services of footwear and leather goods" },
            ISIC: { code: "9523", description: "Repair and maintenance of footwear and leather goods" },
            NACE: { code: "95.23", description: "Repair and maintenance of footwear and leather goods" },
            UK_SIC: { code: "95.23", description: "Repair of footwear and leather goods" },
        },
        "Repair and maintenance of furniture and home furnishings": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.24", description: "Repair and maintenance services of furniture and home furnishings" },
            ISIC: { code: "9524", description: "Repair and maintenance of furniture and home furnishings" },
            NACE: { code: "95.24", description: "Repair and maintenance of furniture and home furnishings" },
            UK_SIC: { code: "95.24", description: "Repair of furniture and home furnishings" },
        },
        "Repair and maintenance of watches, clocks and jewellery": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.25", description: "Repair and maintenance services of watches, clocks and jewellery" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "95.25", description: "Repair and maintenance of watches, clocks and jewellery" },
            UK_SIC: { code: "95.25", description: "Repair of watches, clocks and jewellery" },
        },
        "Repair and maintenance of personal and household goods not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.29", description: "Repair and maintenance services of other personal and household goods" },
            ISIC: { code: "9529", description: "Repair and maintenance of other personal and household goods" },
            NACE: { code: "95.29", description: "Repair and maintenance of personal and household goods not elsewhere classified" },
            UK_SIC: { code: "95.29", description: "Repair of other personal and household goods" },
        },
        "Repair and maintenance of motor vehicles": {
            SCAT: { code: "719\n45\n289\n290\n291\n192\n1\n95", description: "Car Washes (Stand Alone - Hand Car Wash)\nCar Washes (Stand Alone)\nVehicle Repair Workshops & Garages\nVehicle Testing Centres (With Test Tracks)\nVehicle Testing Centres (Without Test Tracks)\nMotor Vehicle Works\nAA/RAC Service Centres and Boxes\nExhaust and Tyre Centres" },
            CPA: { code: "95.31", description: "Repair and maintenance services of motor vehicles" },
            ISIC: { code: "9531", description: "Repair and maintenance of motor vehicles" },
            NACE: { code: "95.31", description: "Repair and maintenance of motor vehicles" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Repair and maintenance of motorcycles": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "95.32", description: "Repair and maintenance services of motorcycles" },
            ISIC: { code: "9532", description: "Repair and maintenance of motorcycles" },
            NACE: { code: "95.32", description: "Repair and maintenance of motorcycles" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles": {
            SCAT: { code: "68\n69\n290\n291", description: "Computer Centres (Non-Purpose Built)\nComputer Centres (Purpose Built)\nVehicle Testing Centres (With Test Tracks)\nVehicle Testing Centres (Without Test Tracks)" },
            CPA: { code: "95.40", description: "Intermediation services of repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles" },
            ISIC: { code: "9540", description: "Intermediation service activities for repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles" },
            NACE: { code: "95.40", description: "Intermediation service activities for repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Washing and cleaning of textile and fur products": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "96.10", description: "Washing and cleaning services of textile and fur products" },
            ISIC: { code: "9610", description: "Washing and cleaning of textile and fur products" },
            NACE: { code: "96.10", description: "Washing and cleaning of textile and fur products" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Hairdressing and barber activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "96.21", description: "Hairdressing and barber services and human hair, unworked" },
            ISIC: { code: "9621", description: "Hairdressing and barber activities" },
            NACE: { code: "96.21", description: "Hairdressing and barber activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Beauty care and other beauty treatment activities": {
            SCAT: { code: "417", description: "Hairdressing/Beauty Salons" },
            CPA: { code: "96.22", description: "Beauty care and other beauty treatment services" },
            ISIC: { code: "9622", description: "Beauty care and other beauty treatment activities" },
            NACE: { code: "96.22", description: "Beauty care and other beauty treatment activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Day spa, sauna and steam bath activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "96.23", description: "Day spa, sauna and steam bath services" },
            ISIC: { code: "9623", description: "Day spa, sauna and steam bath activities" },
            NACE: { code: "96.23", description: "Day spa, sauna and steam bath activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Funeral and related activities": {
            SCAT: { code: "53\n80\n111", description: "Cemeteries (National Scheme)\nCrematoria (With & Without Cemeteries) (National Scheme)\nFuneral Parlours/Chapels Of Rest" },
            CPA: { code: "96.30", description: "Funeral and related services" },
            ISIC: { code: "9630", description: "Funeral and related activities" },
            NACE: { code: "96.30", description: "Funeral and related activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Intermediation service activities for personal services": {
            SCAT: { code: "143", description: "Kennels and Catteries" },
            CPA: { code: "96.40", description: "Intermediation services of personal services" },
            ISIC: { code: "9640", description: "Intermediation service activities for personal services" },
            NACE: { code: "96.40", description: "Intermediation service activities for personal services" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Provision of domestic personal service activities": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "96.91", description: "Domestic personal services" },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "96.91", description: "Provision of domestic personal service activities" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        },
        "Other personal service activities not elsewhere classified": {
            SCAT: { code: "", description: "missing entry in classification" },
            CPA: { code: "96.99", description: "Other personal services n.e.c." },
            ISIC: { code: "", description: "missing entry in classification" },
            NACE: { code: "96.99", description: "Other personal service activities not elsewhere classified" },
            UK_SIC: { code: "", description: "missing entry in classification" },
        }, };
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    const ScatInfoBox = ({ item, landuseCodesData }) => {
        const codeLines = landuseCodesData[item].SCAT.code.split("\n");
        const descriptionLines = landuseCodesData[item].SCAT.description.split("\n");
        return (React.createElement("div", { className: "info-box-container" },
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement(Tooltip, { text: "tooltip" }),
                React.createElement("div", { className: "label" }, "UK NNDA (VOA SCAT):"),
                React.createElement("div", { className: "info-details" }, codeLines.map((line, index) => (React.createElement(React.Fragment, { key: index },
                    React.createElement("div", { className: "code" }, line),
                    React.createElement("div", { className: "description" }, descriptionLines[index] || ""))))))));
    };
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "General Land Use", collapsed: subcat == null || subcat != "2" },
            (props.mapColourScale != "is_domestic") ?
                React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToIsDomesticMapStyle }, "Click to see residential, non-residential and mixed-use buildings.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(SelectDataEntry, { title: dataFields.is_domestic.title, slug: "is_domestic", value: props.building.is_domestic, options: dataFields.is_domestic.items, mode: props.mode, copy: props.copy, onChange: props.onChange, placeholder: dataFields.is_domestic.placeholder, tooltip: dataFields.is_domestic.tooltip }),
            React.createElement(Verification, { slug: "is_domestic", allow_verify: props.user !== undefined && props.building.is_domestic !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("is_domestic"), user_verified_as: props.user_verified.is_domestic, verified_count: props.building.verified.is_domestic }),
            React.createElement(SelectDataEntry, { title: dataFields.is_domestic_source.title, slug: "is_domestic_source", value: props.building.is_domestic_source, options: dataFields.is_domestic_source.items, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.is_domestic_source.tooltip }),
            (props.building.is_domestic_source == commonSourceTypes[0] ||
                props.building.is_domestic_source == commonSourceTypes[1] ||
                props.building.is_domestic_source == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.is_domestic_links.title, slug: "is_domestic_links", value: props.building.is_domestic_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.is_domestic_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        props.building.is_domestic == "Non-residential" || props.building.is_domestic == "Mixed residential/non-residential" ?
            React.createElement(DataEntryGroup, { name: "Specific Land Use/s", collapsed: subcat == null || subcat != "1" },
                (props.mapColourScale != "landuse") ?
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToLandUseMapStyle }, "Click to see specific land use.")
                    :
                        React.createElement(React.Fragment, null),
                React.createElement(MultiDataEntry, { title: "Current land use(s) (NACE level 4 classification)", slug: "current_landuse_group", value: props.building.current_landuse_group, mode: props.mode, copy: props.copy, onChange: props.onChange, confirmOnEnter: true, tooltip: dataFields.current_landuse_group.tooltip, placeholder: "Enter search word/s\u2019 here", copyable: true, autofill: true, showAllOptionsOnEmpty: true }),
                React.createElement(Verification, { slug: "current_landuse_group", allow_verify: props.user !== undefined && props.building.current_landuse_group !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("current_landuse_group"), user_verified_as: props.user_verified.current_landuse_group && props.user_verified.current_landuse_group.join(", "), verified_count: props.building.verified.current_landuse_group }),
                React.createElement(SelectDataEntry, { title: dataFields.current_landuse_source.title, slug: "current_landuse_source", value: props.building.current_landuse_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.current_landuse_source.tooltip, placeholder: dataFields.current_landuse_source.example, options: dataFields.current_landuse_source.items }),
                (props.building.current_landuse_source == commonSourceTypes[0] ||
                    props.building.current_landuse_source == commonSourceTypes[1] ||
                    props.building.current_landuse_source == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { title: dataFields.current_landuse_link.title, slug: "current_landuse_link", value: props.building.current_landuse_link, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.current_landuse_link.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
                React.createElement(DataEntry, { title: dataFields.current_landuse_order.title, tooltip: dataFields.current_landuse_order.tooltip, slug: "current_landuse_order", value: props.building.current_landuse_order, mode: props.mode, disabled: true, copy: props.copy, onChange: props.onChange }),
                React.createElement("input", { className: "form-control", type: "text", placeholder: "If no correct description found, add tag here" }),
                React.createElement("hr", null),
                props.building.current_landuse_group != null ? React.createElement(React.Fragment, null,
                    " ",
                    props.building.current_landuse_group.map((item, index) => (item in landuseCodesData ?
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "info-box-container" },
                                React.createElement(DataTitleCopyable, { slug: "props.slug", slugModifier: "props.slugModifier", title: "Relevant UK landuse codes", tooltip: null, disabled: false }),
                                React.createElement(ScatInfoBox, { item: item, landuseCodesData: landuseCodesData }),
                                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                                    React.createElement(Tooltip, { text: "[UK SIC: The UK Standard Industrial Classification of economic activities](https://www.ons.gov.uk/methodology/classificationsandstandards/ukstandardindustrialclassificationofeconomicactivities)" }),
                                    React.createElement("div", { className: "label" }, "UK SIC:"),
                                    React.createElement("div", { className: "info-details" },
                                        React.createElement("div", { className: "code" }, landuseCodesData[item].UK_SIC.code),
                                        React.createElement("div", { className: "description" }, landuseCodesData[item].UK_SIC.description))),
                                React.createElement(DataTitleCopyable, { slug: "props.slug", slugModifier: "props.slugModifier", title: "Relevant international landuse codes", tooltip: null, disabled: false }),
                                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                                    React.createElement(Tooltip, { text: "[NACE: The Statistical Classification of Economic Activities in the European Community]( https://ec.europa.eu/eurostat/web/nace)" }),
                                    React.createElement("div", { className: "label" }, "NACE:"),
                                    React.createElement("div", { className: "info-details" },
                                        React.createElement("div", { className: "code" }, landuseCodesData[item].NACE.code),
                                        React.createElement("div", { className: "description" }, landuseCodesData[item].NACE.description))),
                                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                                    React.createElement(Tooltip, { text: "[ISIC: The International Standard Industrial Classification of All Economic Activities]( https://unstats.un.org/unsd/classifications/Econ/isic)" }),
                                    React.createElement("div", { className: "label" }, "ISIC:"),
                                    React.createElement("div", { className: "info-details" },
                                        React.createElement("div", { className: "code" }, landuseCodesData[item].ISIC.code),
                                        React.createElement("div", { className: "description" }, landuseCodesData[item].ISIC.description))),
                                React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                                    React.createElement(Tooltip, { text: "[CPA: The Statistical Classification of Products by Activity](https://ec.europa.eu/eurostat/web/cpa)" }),
                                    React.createElement("div", { className: "label" }, "CPA:"),
                                    React.createElement("div", { className: "info-details" },
                                        React.createElement("div", { className: "code" }, landuseCodesData[item].CPA.code),
                                        React.createElement("div", { className: "description" }, landuseCodesData[item].CPA.description)))))
                        : ""))) : "")
            : React.createElement(React.Fragment, null)));
};
const LandUseContainer = withCopyEdit(LandUseView);
export default LandUseContainer;
